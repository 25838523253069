import React, { useState } from "react";
import * as constants from './constants';

import { useAuth0 } from "../react-auth0-spa";
import { withStyles } from '@material-ui/core/styles';


import Grid from '@material-ui/core/Grid';


import Button from '@material-ui/core/Button';



import CircularProgress from '@material-ui/core/CircularProgress';

import DoneIcon from '@material-ui/icons/Done';


import Typography from '@material-ui/core/Typography';

import { FormattedMessage } from "react-intl";

import { v4 as uuidv4 } from 'uuid';
import { ai } from './_helper/TelemetryService';


const styles = theme => ({
    root: {
        background: "red",
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(3),
    },
    paper: {
        margin: theme.spacing(2),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
});

function ApplyArc(props) {

    const { user } = useAuth0();
    const [session, setSession] = useState(uuidv4())
    const [showLoadingLeft, setShowLoadingLeft] = useState(false);
    const [doneLeft, setDoneLeft] = useState(false);
    const [showOverlayLeft, setShowOverlayLeft] = useState(false);
    const [showLoadingRight, setShowLoadingRight] = useState(false);
    const [doneRight, setDoneRight] = useState(false);
    const [showOverlayRight, setShowOverlayRight] = useState(false);   

    const playArc = (side) => {


        let cluster = 0;
        let pairing = 0;
        let deviceName = "";
        let hasMasterGain = false;
        let band1 = 0;
        let band2 = 0;
        let band3 = 0;
        let band4 = 0;
        let masterGain = 0;

        let uri = "";
        let binaural = props.rightDevice.device !== "" && props.leftDevice.device !== "" ? true : false;

        if (side === "left") {
            setShowLoadingLeft(true);
            cluster = props.leftDevice.cluster;
            pairing = props.pairingIdLeft;
            deviceName = props.leftDevice.device.name;
            uri = props.leftDevice.device.uri;
            band1 = props.leftDevice.band1;
            band2 = props.leftDevice.band2;
            band3 = props.leftDevice.band3;
            band4 = props.leftDevice.band4;
            masterGain = props.leftDevice.masterGain;
            hasMasterGain = props.leftDevice.device.masterGain;
        }
        else {
            setShowLoadingRight(true);
            cluster = props.rightDevice.cluster;
            pairing = props.pairingIdRight;
            deviceName = props.rightDevice.device.name;
            uri = props.rightDevice.device.uri;
            band1 = props.rightDevice.band1;
            band2 = props.rightDevice.band2;
            band3 = props.rightDevice.band3;
            band4 = props.rightDevice.band4;
            masterGain = props.rightDevice.masterGain;
            hasMasterGain = props.rightDevice.device.masterGain;
        }

        ai.trackEvent("Apply First Fit", {
            setup: binaural ? "binaural" : "monaural",
            deviceName: deviceName,
            uri: uri,
            band1 : band1,
            band2 : band2,
            band3 : band3,
            band4 : band4,
            cluster : cluster,
            'tenant': user["https://my-domain.my-company.com/user_metadata"].tenant,
            'user': user.nickname,
            session: session
        });



        var commands = "";

        commands += String.fromCharCode('0x20'); //default sc command
        console.log(commands);
        commands += String.fromCharCode('0x1b'); //default vc command
        console.log(commands);
        if (uri === "SIV1:0/18/6144007/1" || uri === "SIV1:0/18/GO/1" || uri === "SIV1:0/18/12348358706/1" || uri === "SIV1:0/18/12348395570/1" || uri === "SIV1:0/18/72022521809666V/1" || uri === "SIV1:0/18/72022521809666C/1" ) {
            switch (parseInt(cluster)) {
                case 1: commands += String.fromCharCode('0xd0'); break;
                case 2: commands += String.fromCharCode('0xd1'); break;
                case 3: commands += String.fromCharCode('0xd2'); break;
                case 4: commands += String.fromCharCode('0xd3'); break;
                case 5: commands += String.fromCharCode('0xd4'); break;
                case 6: commands += String.fromCharCode('0xd5'); break;
                default: throw new Error("invalid cluster");
            }
            
            if(hasMasterGain){
                switch(parseInt(masterGain)){
                    case -8: commands += String.fromCharCode('0xc8'); break;
                    case -7: commands += String.fromCharCode('0xc9'); break;
                    case -6: commands += String.fromCharCode('0xca'); break;
                    case -5: commands += String.fromCharCode('0xcb'); break;
                    case -4: commands += String.fromCharCode('0xcc'); break;
                    case -3: commands += String.fromCharCode('0xcd'); break;
                    case -2: commands += String.fromCharCode('0xce'); break;
                    case -1: commands += String.fromCharCode('0xcf'); break;
                    case 0: commands += String.fromCharCode('0xc0'); break;
                    case 1: commands += String.fromCharCode('0xc1'); break;
                    case 2: commands += String.fromCharCode('0xc2'); break;
                    case 3: commands += String.fromCharCode('0xc3'); break;
                    case 4: commands += String.fromCharCode('0xc4'); break;
                    case 5: commands += String.fromCharCode('0xc5'); break;
                    case 6: commands += String.fromCharCode('0xc6'); break;
                    case 7: commands += String.fromCharCode('0xc7'); break;
                }

                console.log('mg: ' + commands);
            }
        }
        else {
            switch (parseInt(cluster)) {
                case 1: commands += String.fromCharCode('0x40'); break;
                case 2: commands += String.fromCharCode('0x41'); break;
                case 3: commands += String.fromCharCode('0x42'); break;
                case 4: commands += String.fromCharCode('0x43'); break;
                case 5: commands += String.fromCharCode('0x44'); break;
                case 6: commands += String.fromCharCode('0x45'); break;
                default: throw new Error("invalid cluster");
            }
        }
       
        console.log(commands);
        switch (parseInt(band1))
        {
            case -12: commands += String.fromCharCode("0x94"); break;
            case -9: commands += String.fromCharCode("0x95"); break;
            case -6: commands += String.fromCharCode("0x96"); break;
            case -3: commands += String.fromCharCode("0x97"); break;
            case 0: commands += String.fromCharCode("0x90"); break;
            case 3: commands += String.fromCharCode("0x91"); break;
            case 6: commands += String.fromCharCode("0x92"); break;
            case 9: commands += String.fromCharCode("0x93"); break;
            default: console.log("error");
        }
        console.log(commands);
        switch (parseInt(band2))
        {
            case -12: commands += String.fromCharCode("0x9c"); break;
            case -9: commands += String.fromCharCode("0x9d"); break;
            case -6: commands += String.fromCharCode("0x9e"); break;
            case -3: commands += String.fromCharCode("0x9f"); break;
            case 0: commands += String.fromCharCode("0x98"); break;
            case 3: commands += String.fromCharCode("0x99"); break;
            case 6: commands += String.fromCharCode("0x9a"); break;
            case 9: commands += String.fromCharCode("0x9b"); break;
            default: console.log("error");
        }
        console.log(commands);
        switch (parseInt(band3))
        {
            case -12: commands += String.fromCharCode("0xa4"); break;
            case -9: commands += String.fromCharCode("0xa5"); break;
            case -6: commands += String.fromCharCode("0xa6"); break;
            case -3: commands += String.fromCharCode("0xa7"); break;
            case 0: commands += String.fromCharCode("0xa0"); break;
            case 3: commands += String.fromCharCode("0xa1"); break;
            case 6: commands += String.fromCharCode("0xa2"); break;
            case 9: commands += String.fromCharCode("0xa3"); break;
            default: console.log("error");
        }
        console.log(commands);
        switch (parseInt(band4))
        {
            case -12: commands += String.fromCharCode("0xac"); break;
            case -9: commands += String.fromCharCode("0xad"); break;
            case -6: commands += String.fromCharCode("0xae"); break;
            case -3: commands += String.fromCharCode("0xaf"); break;
            case 0: commands += String.fromCharCode("0xa8"); break;
            case 3: commands += String.fromCharCode("0xa9"); break;
            case 6: commands += String.fromCharCode("0xaa"); break;
            case 9: commands += String.fromCharCode("0xab"); break;
            default: console.log("error");
        }
        console.log(commands);
        var b64 = window.btoa(commands);

        var b64UriEncoded = encodeURI(b64);

        const audioUrl = "https://hiarctonegenerator.cloudapi.sivantos.com.cn/api/Tones?brandId=15&pairAddress=" + pairing + "&base64EncodedCommands=" + b64UriEncoded;


        let audio = new Audio();
        audio.src = audioUrl;
        audio.load();

        audio.oncanplaythrough = () => {
            audio.play();
        }

        audio.onended = () => {
            if (side === "left") {
                setShowLoadingLeft(false);
                setDoneLeft(true);
                if (!doneRight && props.rightDevice.device !== "")
                    setShowOverlayRight(true);
            }
            else {
                setShowLoadingRight(false);
                setDoneRight(true);
                if (!doneLeft && props.leftDevice.device !== "")
                    setShowOverlayLeft(true);
            }
        };
    }

    const hideLoadingOverlay = (side) => {
        if (side === "left") {
            setShowOverlayLeft(false);
        }
        else {
            setShowOverlayRight(false);
        }
    }


    const handleClose = () => {
        props.resetSettings();
        props.handleClose();
    }

    return (
        <div>

            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center">
                <Grid item xs={12}  >
                    <FormattedMessage
                        id="firstfit.instructions1"
                        defaultMessage="Make sure that all devices around you are turned off to avoid accidental configuration." />
                </Grid>

                <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
                    <FormattedMessage
                        id="firstfit.instructions2"
                        defaultMessage="Open and close the battery door and then press 'Apply' to start configuration. Turn off the device when the configuration is done!" />
                </Grid>

                <Grid item xs={6} align="center">
                    <Typography variant="h5" style={{ color: constants.COLOR_LEFT }}>
                        <FormattedMessage id="general.left" defaultMessage="Left" />
                    </Typography>
                </Grid>


                <Grid item xs={6} align="center">
                    <Typography variant="h5" style={{ color: constants.COLOR_RIGHT }}>
                        <FormattedMessage id="general.right" defaultMessage="Right" />
                    </Typography>
                </Grid>


                <Grid item xs={6} className={props.classes.grid} align="center">
                    {
                        showOverlayLeft || props.leftDevice.device === "" ?
                            null :
                            props.leftDevice.device.name
                    }
                </Grid>

                <Grid item xs={6} className={props.classes.grid} align="center" >
                    {
                        showOverlayRight || props.rightDevice.device === "" ?
                            null :
                            props.rightDevice.device.name
                    }
                </Grid>

                <Grid item xs={6} className={props.classes.grid} align="center">
                    {
                        showOverlayLeft ?
                            <FormattedMessage
                                id="general.turnoffotherside"
                                defaultMessage="Turn off the other side before you continue." />
                            :
                            props.leftDevice.device === "" ?
                                null :
                                "Cluster " + props.leftDevice.cluster
                    }
                </Grid>

                <Grid item xs={6} className={props.classes.grid} align="center">
                    {
                        showOverlayRight ?
                            <FormattedMessage
                                id="general.turnoffotherside"
                                defaultMessage="Turn off the other side before you continue." />
                            :
                            props.rightDevice.device === "" ?
                                null :
                                "Cluster " + props.rightDevice.cluster
                    }
                </Grid>

                <Grid item xs={6} className={props.classes.grid} align="center">
                    {
                        showOverlayLeft ?
                          null
                            :
                            props.leftDevice.device === "" ?
                                null :
                                "Band 1: " + props.leftDevice.band1
                    }
                </Grid>

                <Grid item xs={6} className={props.classes.grid} align="center">
                    {
                        showOverlayRight ?
                         null
                            :
                            props.rightDevice.device === "" ?
                                null :
                                "Band 1: " + props.rightDevice.band1
                    }
                </Grid>

                <Grid item xs={6} className={props.classes.grid} align="center">
                    {
                        showOverlayLeft ?
                          null
                            :
                            props.leftDevice.device === "" ?
                                null :
                                "Band 2: " + props.leftDevice.band2
                    }
                </Grid>

                <Grid item xs={6} className={props.classes.grid} align="center">
                    {
                        showOverlayRight ?
                           null
                            :
                            props.rightDevice.device === "" ?
                                null :
                                "Band 2: " + props.rightDevice.band2
                    }
                </Grid>

                <Grid item xs={6} className={props.classes.grid} align="center">
                    {
                        showOverlayLeft ?
                          null
                            :
                            props.leftDevice.device === "" ?
                                null :
                                "Band 3: " + props.leftDevice.band3
                    }
                </Grid>

                <Grid item xs={6} className={props.classes.grid} align="center">
                    {
                        showOverlayRight ?
                           null
                            :
                            props.rightDevice.device === "" ?
                                null :
                                "Band 3: " + props.rightDevice.band3
                    }
                </Grid>

                <Grid item xs={6} className={props.classes.grid} align="center">
                    {
                        showOverlayLeft ?
                          null
                            :
                            props.leftDevice.device === "" ?
                                null :
                                "Band 4: " + props.leftDevice.band4
                    }
                </Grid>

                <Grid item xs={6} className={props.classes.grid} align="center">
                    {
                        showOverlayRight ?
                           null
                            :
                            props.rightDevice.device === "" ?
                                null :
                                "Band 4: " + props.rightDevice.band4
                    }
                </Grid>

                <Grid item xs={6} className={props.classes.grid} align="center">
                    {
                        showOverlayLeft ?
                          null
                            :
                            props.leftDevice.device === "" ?
                                null :
                                "Master Gain: " + props.leftDevice.masterGain
                    }
                </Grid>

                <Grid item xs={6} className={props.classes.grid} align="center">
                    {
                        showOverlayRight ?
                           null
                            :
                            props.rightDevice.device === "" ?
                                null :
                                "Master Gain: " + props.rightDevice.masterGain
                    }
                </Grid>



                <Grid item xs={6} className={props.classes.grid} align="center">
                    {
                        showOverlayLeft ?
                            <Button variant="contained" color="primary" onClick={() => hideLoadingOverlay("left")}>
                                <FormattedMessage
                                    id="general.ok"
                                    defaultMessage="OK" />
                            </Button> :
                            props.leftDevice.device === "" ?
                                null :
                                showLoadingLeft ?
                                    <Grid
                                        container
                                        spacing={0}
                                        direction="column"
                                        alignItems="center"
                                        justify="center">
                                        <CircularProgress className={props.classes.button}  disableShrink />
                                    </Grid>
                                    : <Button className={props.classes.button} disabled={showLoadingRight} variant={!doneLeft ? "contained" : "outlined"} color="primary" onClick={() => playArc("left")}>
                                        <FormattedMessage
                                            id="general.applyfirstfit"
                                            defaultMessage="Apply" />
                                    </Button>
                    }
                </Grid>


                <Grid item xs={6} className={props.classes.grid} align="center">
                    {
                        showOverlayRight ?
                            <Button variant="contained" color="primary" onClick={() => hideLoadingOverlay("right")}>
                                <FormattedMessage
                                    id="general.ok"
                                    defaultMessage="OK" />
                            </Button> :

                            props.rightDevice.device === "" ?
                                null :
                                showLoadingRight ?
                                    <Grid
                                        container
                                        spacing={0}
                                        direction="column"
                                        alignItems="center"
                                        justify="center">
                                        <CircularProgress className={props.classes.button}  disableShrink />
                                    </Grid>
                                    : <Button className={props.classes.button} disabled={showLoadingLeft} variant={!doneRight ? "contained" : "outlined"} color="primary" onClick={() => playArc("right")}>
                                        <FormattedMessage
                                            id="general.applyfirstfit"
                                            defaultMessage="Apply" />
                                    </Button>

                    }
                </Grid>

                <Grid item xs={6} className={props.classes.grid} align="center">
                    {
                        showOverlayLeft || props.leftDevice.device === "" ?
                            null :
                            <DoneIcon style={{ visibility: doneLeft ? "visible" : "hidden", color: "green", fontSize: "35pt" }} />

                    }
                </Grid>


                <Grid item xs={6} className={props.classes.grid} align="center">
                    {
                        showOverlayRight || props.rightDevice.device === "" ?
                            null :
                            <DoneIcon style={{ visibility: doneRight ? "visible" : "hidden", color: "green", fontSize: "35pt" }} />

                    }
                </Grid>

                <Grid item className={props.classes.grid}>
                    <Button  className={props.classes.button} color="primary" onClick={handleClose}>
                        <FormattedMessage
                            id="general.close"
                            defaultMessage="Close" />
                    </Button>
                </Grid>
            </Grid>


        </div>
    )
}
export default withStyles(styles, { withTheme: true })(ApplyArc);