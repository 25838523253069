import React from 'react';
import './App.css';
import {Routes} from './Components/Routes'
import { useAuth0 } from "./react-auth0-spa";
import { BrowserRouter } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

// Or Create your Own theme:
const theme = createMuiTheme({
  palette: {
    primary:{
      main: '#658494'
    },
    secondary: {
        main: '#FF6D2E'
      }
    }
  },
)

function App() {
  const { loading } = useAuth0();

  if (loading) {
    return <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{height: '100vh'}}
    > <CircularProgress disableShrink /></Grid>;
  }

  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </div>
    </MuiThemeProvider>
  );
}

export default App;
