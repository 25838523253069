import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from "react";
import DeviceSelect from "./DeviceSelect";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from "react-intl";
import Slider from '@material-ui/core/Slider';
import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
    root: {
        background: "red",
        flexGrow: 1,
    },
    grid: {
        padding: theme.spacing(1),
    }
});

const VolumeChangeSide = forwardRef((props, ref) => {

    const [selectedDevice, setSelectedDevice] = useState("");
    const [volume, setVolume] = useState(3);
    const [soundBalance, setsoundBalance] = useState(0);
    const deviceSelector = useRef();
    const [vcEnabled, setVcEnabled] = React.useState(false);
    const [scEnabled, setScEnabled] = React.useState(false);

    useImperativeHandle(ref, () => ({
        reset: () => {
            reset();
        }
    }))

    useEffect(() => {
        const selection = {
            device: selectedDevice,
            volume: volume,
            soundBalance: soundBalance,
            vcEnabled: vcEnabled,
            scEnabled: scEnabled
        };
        props.updateSelection(selection);
    }, [volume, soundBalance, selectedDevice, vcEnabled]);

    useEffect(() => {
       setVolume(3);
    }, [vcEnabled]);

    useEffect(() => {
        setsoundBalance(0);
     }, [scEnabled]);


    // const handleClusterChange = (event, newCluster) => {
    //     setSelectedCluster(newCluster);
    // }

    const handleSetSelectedDevice = (device) => {
        setSelectedDevice(device);
        setVolume(3);
        setsoundBalance(0);
        setVcEnabled(false);
        setScEnabled(false);
    }

    const reset = () => {
        setSelectedDevice("");
        setVolume(3);
        setsoundBalance(0);
        setVcEnabled(false);
        setScEnabled(false);
        deviceSelector.current.reset();
    }

    return (
        <Grid container justify="center" alignItems="center">
            <Grid item xs={12} className={props.classes.grid}>
                <h2 style={{ color: props.color }}>{props.side}</h2>
            </Grid>
            <Grid item xs={12} className={props.classes.grid}>
                <DeviceSelect ref={deviceSelector} selectedDevice={handleSetSelectedDevice}></DeviceSelect>
            </Grid>
            <Grid item className={props.classes.grid}>
                <FormattedMessage
                    id="general.volume"
                    defaultMessage="Volume"
                />
            </Grid>
            <Grid container className={props.classes.grid} alignItems="center">
                <Checkbox
                disabled={!selectedDevice}
                    checked={vcEnabled}
                    onChange={(event, value)  =>  setVcEnabled(value)}
                />
                <Slider
                    disabled={!selectedDevice || !vcEnabled}
                    value={volume}
                    onChange={(event, value) => setVolume(value)}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="auto"
                    style={{ width: 250 }}
                    step={1}
                    marks
                    track={false}
                    min={0}
                    max={+15} />
                      {selectedDevice !== "" && vcEnabled ? volume : ""}
            </Grid>

            <Grid item className={props.classes.grid}>
                <FormattedMessage
                    id="general.soundbalance"
                    defaultMessage="Sound Balance"
                />
            </Grid>
            <Grid container className={props.classes.grid} alignItems="center">
                <Checkbox
                disabled={!selectedDevice}
                    checked={scEnabled}
                    onChange={(event, value)  =>  setScEnabled(value)}
                />
                <Slider
                    disabled={!selectedDevice || !scEnabled}
                    value={soundBalance}
                    onChange={(event, value) => setsoundBalance(value)}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="auto"
                    style={{ width: 250 }}
                    step={1}
                    marks
                    track={false}
                    min={-8}
                    max={+7} />
                      {selectedDevice !== "" && scEnabled ? soundBalance : ""}
            </Grid>
          
        </Grid>
    )

});
export default withStyles(styles, { withTheme: true })(VolumeChangeSide);