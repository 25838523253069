import React, { useRef, useEffect, useState } from "react";

import { useAuth0 } from "../react-auth0-spa";


import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import * as constants from './constants';
import Paper from '@material-ui/core/Paper';

import { FormattedMessage } from "react-intl";


import TenantSettingsService from './../Helpers/TenantSettingsService'

import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';

import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';

import MenuBar from './MenuBar';
import RecommendationSide from './RecommendationSide';
import ApiService from "../Helpers/HttpConnect";

import CircularProgress from '@material-ui/core/CircularProgress';


import { ai } from './_helper/TelemetryService';

import FittingRangeChart from './FittingRangeChart';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    root: {
        background: "red",
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(3),
    },
    paper: {
        margin: theme.spacing(2),
        maxWidth: "1000px",
        align: "center",
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    tabbar: {
        backgroundColor: '#dddddd',
    },
    toolbar: theme.mixins.toolbar,
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        align: "center",
    },
    greeting: {
        flexGrow: 1,
        align: "left",
    },
    listItem: {
        maxWidth: "400px",
    },
    list: {
        maxWidth: "430px",
    }
});


const Recommendation = (props) => {

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let paramsLeft = params.get("left");
    let paramsRight = params.get("right");



    const { user } = useAuth0();
    const [leftSelection, setLeftSelection] = useState({ Hz500: 0, Hz1K: 0, Hz2K: 0, Hz4K: 0});
    const [rightSelection, setRightSelection] = useState({ Hz500: 0, Hz1K: 0, Hz2K: 0, Hz4K: 0});
    const [inputValid, setInputValid] = useState(false);
    const [recommendations, setRecommendations] = useState(false);
    const { classes } = props;
    const [showRecommendations, setShowRecommendations] = React.useState(false);
    const [resultsLoading, setResultsLoading] = React.useState(false);
    let supportedHis = TenantSettingsService.get(user);


    let chartRef = React.createRef();



    const leftSelector = useRef(null);
    const rightSelector = useRef(null);

    useEffect(() => {
        validateData();
    }, [leftSelection, rightSelection]);



    useEffect(() => {
        if (recommendations) setShowRecommendations(true);
    }, [recommendations]);


    const updateLeftSide = (selection) => {
        setShowRecommendations(false);
        setLeftSelection(selection);
    }

    const updateRightSide = (selection) => {
        setShowRecommendations(false);
        setRightSelection(selection);
    }

    const validateData = () => {

        let leftValid = (leftSelection.Hz500 >= 0 && leftSelection.Hz500 <= 120) && (leftSelection.Hz1K >= 0 && leftSelection.Hz1K <= 120) && (leftSelection.Hz2K >= 0 && leftSelection.Hz2K <= 120) && (leftSelection.Hz4K >= 0 && leftSelection.Hz4K <= 120);
        let rightValid = (rightSelection.Hz500 >= 0 && rightSelection.Hz500 <= 120) && (rightSelection.Hz1K >= 0 && rightSelection.Hz1K <= 120) && (rightSelection.Hz2K >= 0 && rightSelection.Hz2K <= 120) && (rightSelection.Hz4K >= 0 && rightSelection.Hz4K <= 120);


        setInputValid(leftValid && rightValid);
    }



    const handleCalculateBtnClick = (left, right) => {

        setResultsLoading(true);

        let data = {
            requests: {
                1: left,
                2: right
            },
            includedHIs: supportedHis.Devices.map((device) => device.uri)
        };

        ApiService.postDeviceRecommendation(data, false)
            .then(res => {

                setRecommendations(res);

                ai.trackEvent("Recommendation calculated", {
                    LeftHz500: left.Hz500,
                    LeftHz1K: left.Hz1K,
                    LeftHz2K: left.Hz2K,
                    LeftHz4K: left.Hz4K,
                    RightHz500: right.Hz500,
                    RightHz1K: right.Hz1K,
                    RightHz2K: right.Hz2K,
                    RightHz4K: right.Hz4K,
                    LeftRec1Uri: res['1'][0] !== undefined ? res['1'][0].hearingAid.uri : null,
                    LeftRec1Rec: res['1'][0] !== undefined ? res['1'][0].hearingAid.recommended : null,
                    LeftRec2Uri: res['1'][1] !== undefined ? res['1'][1].hearingAid.uri : null,
                    LeftRec2Rec: res['1'][1] !== undefined ? res['1'][1].hearingAid.recommended : null,
                    LeftRec3Uri: res['1'][2] !== undefined ? res['1'][2].hearingAid.uri : null,
                    LeftRec3Rec: res['1'][2] !== undefined ? res['1'][2].hearingAid.recommended : null,
                    LeftRec4Uri: res['1'][3] !== undefined ? res['1'][3].hearingAid.uri : null,
                    LeftRec4Rec: res['1'][3] !== undefined ? res['1'][3].hearingAid.recommended : null,
                    LeftRec5Uri: res['1'][4] !== undefined ? res['1'][4].hearingAid.uri : null,
                    LeftRec5Rec: res['1'][4] !== undefined ? res['1'][4].hearingAid.recommended : null,
                    LeftRec6Uri: res['1'][5] !== undefined ? res['1'][5].hearingAid.uri : null,
                    LeftRec6Rec: res['1'][5] !== undefined ? res['1'][5].hearingAid.recommended : null,
                    LeftRec7Uri: res['1'][6] !== undefined ? res['1'][6].hearingAid.uri : null,
                    LeftRec7Rec: res['1'][6] !== undefined ? res['1'][6].hearingAid.recommended : null,
                    RightRec1Uri: res['2'][0] !== undefined ? res['2'][0].hearingAid.uri : null,
                    RightRec1Rec: res['2'][0] !== undefined ? res['2'][0].hearingAid.recommended : null,
                    RightRec2Uri: res['2'][1] !== undefined ? res['2'][1].hearingAid.uri : null,
                    RightRec2Rec: res['2'][1] !== undefined ? res['2'][1].hearingAid.recommended : null,
                    RightRec3Uri: res['2'][2] !== undefined ? res['2'][2].hearingAid.uri : null,
                    RightRec3Rec: res['2'][2] !== undefined ? res['2'][2].hearingAid.recommended : null,
                    RightRec4Uri: res['2'][3] !== undefined ? res['2'][3].hearingAid.uri : null,
                    RightRec4Rec: res['2'][3] !== undefined ? res['2'][3].hearingAid.recommended : null,
                    RightRec5Uri: res['2'][4] !== undefined ? res['2'][4].hearingAid.uri : null,
                    RightRec5Rec: res['2'][4] !== undefined ? res['2'][4].hearingAid.recommended : null,
                    RightRec6Uri: res['2'][5] !== undefined ? res['2'][5].hearingAid.uri : null,
                    RightRec6Rec: res['2'][5] !== undefined ? res['2'][5].hearingAid.recommended : null,
                    RightRec7Uri: res['2'][6] !== undefined ? res['2'][6].hearingAid.uri : null,
                    RightRec7Rec: res['2'][6] !== undefined ? res['2'][6].hearingAid.recommended : null,
                    'tenant': user["https://my-domain.my-company.com/user_metadata"].tenant,
                    'user': user.nickname
                });


                setResultsLoading(false);
            })
            .catch(error => {
                setResultsLoading(false);
                console.log("Error", error);
            });


    }


    useEffect(() => {
        let calc = false;
        let leftData = leftSelection;
        let rightData = rightSelection;
        if (paramsLeft !== null) {
            calc = true;
            leftData = {
                Hz500: paramsLeft.split(',')[0],
                Hz1K: paramsLeft.split(',')[1],
                Hz2K: paramsLeft.split(',')[2],
                Hz4K: paramsLeft.split(',')[3]
            };
        }


        if (paramsRight !== null) {
            calc = true;
            rightData = {
                Hz500: paramsRight.split(',')[0],
                Hz1K: paramsRight.split(',')[1],
                Hz2K: paramsRight.split(',')[2],
                Hz4K: paramsRight.split(',')[3]
            };
        }

        

        if(calc){
            setLeftSelection(leftData);
            setRightSelection(rightData);
            handleCalculateBtnClick(leftData, rightData);
        }
    }, []);

    const getRecommendations = (side) => {

        let audiogram = side === "left" ? leftSelection : rightSelection;
        let rec = side === "left" ? recommendations["1"] : recommendations["2"];

        const list = rec.map((dev) => {
            return <div><Grid
                container
                style={{ marginBottom: 10 }}
                spacing={0}
                direction="row"
                alignItems="center"
                justify="center">
                <Grid
                    style={{ padding: 20 }}
                    container
                    direction="row"
                    alignItems="center"
                    justify="center">
                    <Grid item xs>
                        <Avatar>
                            {dev.hearingAid.recommended ? <DoneIcon style={{ color: "green" }} /> : <ClearIcon style={{ color: "darkred" }} />}
                        </Avatar>
                    </Grid>
                    <Grid item xs>
                        <span style={{ fontWeight: 'bold' }}>{(supportedHis.Devices.find(a => a.uri == dev.hearingAid.uri)).name}</span>
                    </Grid>
                    <Grid item xs>
                        {'Cluster: ' + dev.cluster}
                    </Grid>
                    <Grid item xs>
                        {'Gain: ' + dev.gain}
                    </Grid>
                </Grid>
                <Grid item xs>
                    <FittingRangeChart audiogram={audiogram} color={side === 'left' ? constants.COLOR_LEFT : constants.COLOR_RIGHT} uri={dev.hearingAid.uri} />
                </Grid>
            </Grid>
                <Divider />
            </div>;
        });
        return list;
    }



    return (
        <div style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
            <MenuBar />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center">
                    <Grid item >
                        <Paper className={classes.paper} width="1000px">
                            <Grid
                                container
                                direction="column"
                                alignItems="center"
                                justify="center">
                                <form onSubmit={(e) => { e.preventDefault(); handleCalculateBtnClick(leftSelection, rightSelection); }}>
                                    <Grid
                                        container
                                        justify="center"
                                        spacing={3}
                                        alignItems="center"  >
                                        <Grid item xs>
                                            <Grid container justify="center" alignItems="center">
                                                <Grid item xs={12} className={props.classes.grid}>
                                                    <h2 style={{ color: constants.COLOR_LEFT }}><FormattedMessage id="general.left" defaultMessage="Left" /></h2>
                                                </Grid>
                                                <Grid item xs={12} className={props.classes.grid}>
                                                    <TextField
                                                        onChange={(event) => {setLeftSelection({ ...leftSelection, Hz500: event.target.value }); setShowRecommendations(false); }}
                                                        value={leftSelection.Hz500}
                                                        type="number"
                                                        style={{ margin: 10 }}
                                                        id="outlined-basic"
                                                        label='500 Hz'
                                                        variant="outlined" />
                                                    <TextField
                                                        onChange={(event) =>{ setLeftSelection({ ...leftSelection, Hz1K: event.target.value }); setShowRecommendations(false); }}
                                                        style={{ margin: 10 }}
                                                        type="number"
                                                        id="outlined-basic"
                                                        label='1000 Hz'
                                                        value={leftSelection.Hz1K}
                                                        variant="outlined" />
                                                    <TextField
                                                        onChange={(event) =>{ setLeftSelection({ ...leftSelection, Hz2K: event.target.value }); setShowRecommendations(false); }}
                                                        value={leftSelection.Hz2K}
                                                        type="number"
                                                        style={{ margin: 10 }}
                                                        id="outlined-basic"
                                                        label='2000 Hz'
                                                        variant="outlined" />
                                                    <TextField
                                                        onChange={(event) => {setLeftSelection({ ...leftSelection, Hz4K: event.target.value }); setShowRecommendations(false); }}
                                                        value={leftSelection.Hz4K}
                                                        type="number"
                                                        style={{ margin: 10 }}
                                                        id="outlined-basic"
                                                        label='4000 Hz'
                                                        variant="outlined" />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Divider orientation="vertical" />
                                        <Grid item xs>
                                            <Grid container justify="center" alignItems="center">
                                                <Grid item xs={12} className={props.classes.grid}>
                                                    <h2 style={{ color: constants.COLOR_RIGHT }}><FormattedMessage id="general.right" defaultMessage="Right" /></h2>
                                                </Grid>
                                                <Grid item xs={12} className={props.classes.grid}>
                                                    <TextField
                                                        onChange={(event) =>{ setRightSelection({ ...rightSelection, Hz500: event.target.value }); setShowRecommendations(false); }}
                                                        value={rightSelection.Hz500}
                                                        type="number"
                                                        style={{ margin: 10 }}
                                                        id="outlined-basic"
                                                        label='500 Hz'
                                                        variant="outlined" />
                                                    <TextField
                                                        onChange={(event) => {setRightSelection({ ...rightSelection, Hz1K: event.target.value }); setShowRecommendations(false); }}
                                                        style={{ margin: 10 }}
                                                        type="number"
                                                        id="outlined-basic"
                                                        label='1000 Hz'
                                                        value={rightSelection.Hz1K}
                                                        variant="outlined" />
                                                    <TextField
                                                        onChange={(event) => {setRightSelection({ ...rightSelection, Hz2K: event.target.value }); setShowRecommendations(false); }}
                                                        value={rightSelection.Hz2K}
                                                        type="number"
                                                        style={{ margin: 10 }}
                                                        id="outlined-basic"
                                                        label='2000 Hz'
                                                        variant="outlined" />
                                                    <TextField
                                                        onChange={(event) => {setRightSelection({ ...rightSelection, Hz4K: event.target.value }); setShowRecommendations(false); }}
                                                        value={rightSelection.Hz4K}
                                                        type="number"
                                                        style={{ margin: 10 }}
                                                        id="outlined-basic"
                                                        label='4000 Hz'
                                                        variant="outlined" />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        justify="center"
                                        spacing={3}
                                        alignItems="center"  >
                                        <Grid item xs={12}>
                                            {inputValid ?

                                                resultsLoading ?
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                        direction="column"
                                                        alignItems="center"
                                                        justify="center">
                                                        <CircularProgress className={props.classes.button} disableShrink />
                                                    </Grid>
                                                    :
                                                    <Button variant="contained" type="submit" color="primary" className={classes.button} >
                                                        <FormattedMessage
                                                            id="general.calculateRecommendation"
                                                            defaultMessage="Calculate Recommendation" />
                                                    </Button>
                                                :
                                                <FormattedMessage
                                                    id="general.audiogramvaluesoutofrange"
                                                    defaultMessage="Audiogram values must be between 0 and 120" />
                                            }

                                        </Grid>
                                    </Grid>
                                </form>
                                <Grid
                                    container
                                    justify="center"
                                    spacing={3}
                                    alignItems="center"  >
                                    <Grid item xs={6} alignItems="center"  >
                                        {showRecommendations ?

                                            <List dense={true} className={classes.list}>
                                                {getRecommendations("left")}
                                            </List>

                                            : null}
                                    </Grid>
                                    <Divider orientation="vertical" />
                                    <Grid item xs>
                                        {showRecommendations ?


                                            <List dense={true} className={classes.list}>
                                                {getRecommendations("right")}
                                            </List>

                                            : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </main>
        </div >
    )
}

export default withStyles(styles, { withTheme: true })(Recommendation);