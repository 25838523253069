import React from "react";
import { Route, Switch } from "react-router-dom";
import ClusterChange from "./ClusterChange";
import FourBandChange from "./FourBandChange";
import PrivateRoute from './PrivateRoute'
import FineTuning from './FineTuning';
import QNALink from './GenerateAssessmentLink';
import Recommendation from './Recommendation';
import FirstFit from "./FirstFit"
import History from "./History"
import GenerateShoeboxLink from "./GenerateShoeboxLink";

export const Routes = () => (
  <div>
    <Switch>
      <PrivateRoute exact path="/" component={FineTuning} />
      <PrivateRoute path="/qnalink" component={QNALink} />
      <PrivateRoute path="/recommendation" component={Recommendation} />
      <PrivateRoute path="/firstfit" component={FirstFit} />
      <PrivateRoute path="/ClusterChange" component={ClusterChange} />
      <PrivateRoute path="/FourBandChange" component={FourBandChange} />
      <PrivateRoute path="/shoeboxlink" component={GenerateShoeboxLink} />
      <PrivateRoute path="/History" component={History} />
    </Switch>
  </div>
);
