import React, { useState, useEffect, useRef } from "react";
import ClusterChangeSide from "./ClusterChangeSide"
import ClusterChangeStepper from './ClusterChangeStepper';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import * as constants from './constants';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { FormattedMessage } from "react-intl";


const styles = theme => ({
    root: {
        background: "red",
        flexGrow: 1,
    },
    paper: {
        margin: theme.spacing(2),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
});

function ClusterChange(props) {

    const [dialogOpen, setDialogOpen] = useState(false);
    const [leftSelection, setLeftSelection] = useState("");
    const [rightSelection, setRightSelection] = useState("");
    const [inputValid, setInputValid] = useState(false);

    const leftSelector = useRef(null);
    const rightSelector = useRef(null);

    useEffect(() => {
        validateData();
    }, [leftSelection]);

    useEffect(() => {
        validateData();
    }, [rightSelection]);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    }

    const updateLeftDevice = (selection) => {
        setLeftSelection(selection);
    }

    const updateRightDevice = (selection) => {
        setRightSelection(selection);
    }

    const resetSettings = () => {
        leftSelector.current.reset();
        rightSelector.current.reset();
    }

   const validateData = () => {
        var leftUnselected = (leftSelection.device === "" && leftSelection.cluster === 0) || leftSelection === "";
        var leftSelected = (leftSelection.device !== "" && leftSelection.cluster > 0);

        var rightUnselected = (rightSelection.device === "" && rightSelection.cluster === 0) || rightSelection === "";
        var rightSelected = (rightSelection.device !== "" && rightSelection.cluster > 0);

        let isValid = (leftUnselected && rightSelected) || (leftSelected && rightUnselected) || (leftSelected && rightSelected);

        setInputValid(isValid);
    }

   
        return (
            <div>
                <Grid
                    container
                    justify="center"
                    spacing={3}
                    alignItems="center"  >
                    <Grid item xs>
                        <ClusterChangeSide side={  <FormattedMessage id="general.left" defaultMessage="Left" />} ref={leftSelector} color={constants.COLOR_LEFT} updateSelection={updateLeftDevice} />
                    </Grid>
                    <Divider orientation="vertical" />
                    <Grid  item xs>
                        <ClusterChangeSide side={  <FormattedMessage id="general.right" defaultMessage="Right" />} ref={rightSelector} color={constants.COLOR_RIGHT} updateSelection={updateRightDevice} />
                    </Grid>
                </Grid>
                <Grid
                    container
                    justify="center"
                    spacing={3}
                    alignItems="center"  >
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" disabled={!inputValid} onClick={handleDialogOpen}>
                            <FormattedMessage
                                id="general.savesettingsgetlink"
                                defaultMessage="SAVE SETTINGS & GET LINK" />
                        </Button>
                    </Grid>
                </Grid>
                <Dialog
                disableBackdropClick
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogContent>
                        <ClusterChangeStepper
                            leftDevice={leftSelection}
                            rightDevice={rightSelection}
                            resetSettings={resetSettings}
                            handleClose={handleDialogClose} />
                    </DialogContent>
                </Dialog>
            </div>
        )
    }

export default withStyles(styles, { withTheme: true })(ClusterChange);