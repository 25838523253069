import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { useAuth0 } from "../react-auth0-spa";
import TenantSettingsService from './../Helpers/TenantSettingsService'
import { FormattedMessage } from "react-intl";


const DeviceSelect = forwardRef((props, ref) => {


  const { user } = useAuth0();
  const [selectedDevice, setSelectedDevice] = useState("");

  useImperativeHandle(ref, () => ({
    reset: () => {
      setSelectedDevice("");
    }
  }))

  let filteredDeviceList = TenantSettingsService.get(user).Devices;

  if (props.settingsType === "4bd")
    filteredDeviceList = filteredDeviceList.filter((device) => { return device.fourBand; });

    useEffect(() => {

      var deviceInList = filteredDeviceList.find((device) =>{ return device.uri === props.preSelected;});
      console.log(deviceInList);
      if(deviceInList){
        setSelectedDevice(deviceInList);
      }
    }, []);

  useEffect(() => {
    props.selectedDevice(selectedDevice);
  }, [selectedDevice]);

  const handleChange = (event) => {
    setSelectedDevice(event.target.value);
  }

  return (
    <FormControl>
      <InputLabel shrink id="demo-simple-select-placeholder-label-label">
        <FormattedMessage
          id="general.device"
          defaultMessage="Device" />
      </InputLabel>
      <Select
        labelId="demo-simple-select-placeholder-label-label"
        id="demo-simple-select-placeholder-label"
        onChange={handleChange}
        value={selectedDevice}
        displayEmpty
        style={{ minWidth: '150px' }}>
        <MenuItem value="" >
          <div style={{ color: 'darkred' }}>NONE</div>
        </MenuItem>
        {filteredDeviceList.map(device => (
          <MenuItem value={device} key={device.uri}>{device.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
})
export default DeviceSelect



