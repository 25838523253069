import React  from "react";

import { useAuth0 } from "../react-auth0-spa";


import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { FormattedMessage } from "react-intl";

import { IntlConsumer } from "./IntlContext";
import TenantSettingsService from './../Helpers/TenantSettingsService'

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TuneIcon from '@material-ui/icons/Tune';
import MenuIcon from '@material-ui/icons/Menu';
import HeadsetIcon from '@material-ui/icons/Headset';
import SettingsIcon from '@material-ui/icons/Settings';
import ListAltIcon from '@material-ui/icons/ListAlt';

import MuiAlert from '@material-ui/lab/Alert';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

import { Link } from "react-router-dom";


const styles = theme => ({
    root: {
        background: "red",
        flexGrow: 1,
    },
    paper: {
        margin: theme.spacing(2),
        maxWidth: "1000px",
        align: "center",
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    tabbar: {
        backgroundColor: '#dddddd',
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        align: "center",
    },
    greeting: {
        flexGrow: 1,
        align: "left",
    }
});

const drawerWidth = 240;






const Home = (props) => {
    const {  user, logout } = useAuth0();

    // if (!isAuthenticated)
    //     loginWithRedirect();

    const { classes } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileOpen, setMobileOpen] = React.useState(false);
 

   

    let tenantName = TenantSettingsService.get(user).tenantName;
    let canDoShoebox = TenantSettingsService.get(user).canDoShoebox;

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <Divider />
            <List>


                <ListItem button component={Link} to="/recommendation" key="librecommendation" >
                    <ListItemIcon>{<FormatListNumberedIcon />}</ListItemIcon>
                    <ListItemText primary={<FormattedMessage
                        id="general.recommendation"
                        defaultMessage="Recommendation"
                    />} />
                </ListItem>
                <ListItem button component={Link} to="/firstfit" key="librfirstfit" >
                    <ListItemIcon>{<SettingsIcon />}</ListItemIcon>
                    <ListItemText primary={<FormattedMessage
                        id="general.firstfit"
                        defaultMessage="First Fit"
                    />} />
                </ListItem>
                <ListItem button component={Link} to="/" key="libtnfinetuning" >
                    <ListItemIcon>{<TuneIcon />}</ListItemIcon>
                    <ListItemText primary={<FormattedMessage
                        id="general.remotefinetuning"
                    defaultMessage="Remote Fine Tuning"/> } />
                </ListItem>
                {/* <ListItem button component={Link} to="/history" key="libtnhistory" >
                    <ListItemIcon>{<TuneIcon />}</ListItemIcon>
                    <ListItemText primary={<FormattedMessage
                        id="general.history"
                    defaultMessage="History"/> } />
                </ListItem> */}               
                {canDoShoebox && (
                    <ListItem button key="libtnlinksbx" component={Link} to='/shoeboxlink' >
                        <ListItemIcon>{<HeadsetIcon />}</ListItemIcon>
                        <ListItemText primary={<FormattedMessage
                            id="general.shoeboxLink"
                            defaultMessage="Shoebox Link"
                        />} />
                    </ListItem>
                )}
            </List>
        </div>
    );



    return (
        <div style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
            <div>
                {process.env.REACT_APP_MODE !== "PROD" ? (
                    <span style={{ color: "red", background: 'yellow', fontWeight: "bold", position: "absolute", top: 10, fontSize: 30, left: "50%", zIndex: 99999, transform: 'translate(-50%, -50%)' }}>
                        {process.env.REACT_APP_MODE} ENV - TESTING ONLY!
                    </span>
                ) : null}
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Avatar alt={user.nickname} src={user.picture} />
                        <Typography edge="start" variant="subtitle1" className={classes.greeting}>
                            <FormattedMessage
                                id="general.hello"
                                defaultMessage="Hello"
                            /> {user.nickname}
                        </Typography>
                        <Typography variant="subtitle1" className={classes.title}>
                            Home Fitting Admin Portal {process.env.REACT_APP_VERSION} - {tenantName}
                        </Typography>


                        <Button aria-controls="simple-menu" aria-haspopup="true" style={{ color: "white" }} onClick={handleClick}>
                            <FormattedMessage
                                id="general.language"
                                defaultMessage="Language"
                            />
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}>
                            <IntlConsumer>
                                {({ switchToEnglish, switchToChineseSimplified, switchToChineseTraditional, locale }) => (
                                    <div>
                                        <MenuItem onClick={() => { switchToEnglish(); handleClose(); }}>English</MenuItem>
                                        <MenuItem onClick={() => { switchToChineseSimplified(); handleClose(); }}>简体字</MenuItem>
                                        <MenuItem onClick={() => { switchToChineseTraditional(); handleClose(); }}>繁體字</MenuItem>
                                    </div>
                                )}
                            </IntlConsumer>
                        </Menu>

                        <IconButton onClick={() => logout()} className={classes.menuButton} color="inherit" aria-label="menu">
                            <ExitToAppRoundedIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <nav className={classes.drawer} aria-label="mailbox folders">
                    <Hidden xlUp implementation="css">
                        <Drawer
                            variant="temporary"
                            anchor="left"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true,
                            }}>
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden lgDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open>
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>
            </div>
        </div >
    )
}

export default withStyles(styles, { withTheme: true })(Home);