import TenantSettings from './../Components/Tenants.json';

class TenantSettingsService {

  get(user) {
      let tenantId = user["https://my-domain.my-company.com/user_metadata"].tenant;

      for(var i = 0; i < TenantSettings.length; i++){
        if(TenantSettings[i].Tenants.includes(tenantId)){
          return TenantSettings[i].Settings;
        }
      }
      

      return null;
  }

  getSupportedHIs(tenantId){
    for(var i = 0; i < TenantSettings.length; i++){
      if(TenantSettings[i].Tenants.includes(tenantId)){
        return TenantSettings[i].Settings.Devices.map(a => a.uri);
      }
    }
    

    return null;
  }
}

export default new TenantSettingsService();