import React, { useEffect, forwardRef } from "react";



import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import TextField from '@material-ui/core/TextField';



const styles = theme => ({
    root: {
        background: "red",
        flexGrow: 1,
    },
    paper: {
        margin: theme.spacing(2),
        maxWidth: "1000px",
        align: "center",
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    tabbar: {
        backgroundColor: '#dddddd',
    },
    toolbar: theme.mixins.toolbar,
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        align: "center",
    },
    greeting: {
        flexGrow: 1,
        align: "left",
    }
});


const RecommendationSide = forwardRef((props, ref) => {
    const [Hz500, setHz500] = React.useState(0);
    const [Hz1000, setHz1000] = React.useState(0);
    const [Hz2000, setHz2000] = React.useState(0);
    const [Hz4000, setHz4000] = React.useState(0);
    const { classes } = props;

    useEffect(() => {
        let data = {
            Hz500: Hz500,
            Hz1K: Hz1000,
            Hz2K: Hz2000,
            Hz4K: Hz4000
        };

        props.updateAudiogramData(data);
    }, [Hz500, Hz1000, Hz2000, Hz4000]);



    return (
        <Grid container justify="center" alignItems="center">
            <Grid item xs={12} className={props.classes.grid}>
                <h2 style={{ color: props.color }}>{props.side}</h2>
            </Grid>
            <Grid item xs={12} className={props.classes.grid}>
                <TextField
                    onChange={(event) => setHz500(event.target.value)}
                    value={Hz500}
                    type="number"
                    style={{ margin: 10 }}
                    id="outlined-basic"
                    label='500 Hz'
                    variant="outlined" />
                <TextField
                    onChange={(event) => setHz1000(event.target.value)}
                    style={{ margin: 10 }}
                    type="number"
                    id="outlined-basic"
                    label='1000 Hz'
                    value={Hz1000}
                    variant="outlined" />
                <TextField
                    onChange={(event) => setHz2000(event.target.value)}
                    value={Hz2000}
                    type="number"
                    style={{ margin: 10 }}
                    id="outlined-basic"
                    label='2000 Hz'
                    variant="outlined" />
                <TextField
                    onChange={(event) => setHz4000(event.target.value)}
                    value={Hz4000}
                    type="number"
                    style={{ margin: 10 }}
                    id="outlined-basic"
                    label='4000 Hz'
                    variant="outlined" />
            </Grid>
        </Grid>
    )
});

export default withStyles(styles, { withTheme: true })(RecommendationSide);