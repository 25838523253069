import React from "react";

import { useAuth0 } from "../react-auth0-spa";


import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { FormattedMessage } from "react-intl";

import ClusterChange from "./ClusterChange";
import FourBandChange from "./FourBandChange";
import VolumeChange from "./VolumeChange";
import TenantSettingsService from './../Helpers/TenantSettingsService'

import MenuBar from './MenuBar';


const styles = theme => ({
    root: {
        background: "red",
        flexGrow: 1,
    },
    paper: {
        margin: theme.spacing(2),
        maxWidth: "1000px",
        align: "center",
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    tabbar: {
        backgroundColor: '#dddddd',
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        align: "center",
    },
    greeting: {
        flexGrow: 1,
        align: "left",
    }
});

const drawerWidth = 240;



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}



const Home = (props) => {

    const {user } = useAuth0();
    const { classes } = props;
    const [tab, setTab] = React.useState("1");

    const handleChange = (event, newTab) => {
        setTab(newTab);
    };

    const canDoVc = TenantSettingsService.get(user).fineTuning.VC;
    const canDo4BD = TenantSettingsService.get(user).fineTuning.fourBand;
    const canDoCluster = TenantSettingsService.get(user).fineTuning.cluster;

    

    return (
        <div style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
            <MenuBar/>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center">

                    <Grid item >
                        <Paper className={classes.paper} width="1000px">
                            <Tabs
                                className={classes.tabbar}
                                value={tab}
                                onChange={handleChange}
                                variant="fullWidth"
                                indicatorColor="primary"
                                textColor="primary"
                                centered>

                                {canDoCluster ? <Tab value="0" label={<FormattedMessage
                                    id="general.clusterchange"
                                    defaultMessage="Cluster Change"
                                />} /> : null}


                                {canDo4BD ?<Tab value="1" label={<FormattedMessage
                                    id="general.4bdchange"
                                    defaultMessage="4 Band Change"
                                />} /> : null}

                                {canDoVc ? <Tab value="2" label={<FormattedMessage
                                    id="general.vcscchange"
                                    defaultMessage="VC/SC Change"
                                />} /> : null}
                            </Tabs>
                            {canDoCluster ? <TabPanel value={tab} index="0">
                                <ClusterChange />
                            </TabPanel> : null}
                            {canDo4BD ? <TabPanel value={tab} index="1">
                                <FourBandChange />
                            </TabPanel> : null}
                            {canDoVc ? <TabPanel value={tab} index="2">
                                <VolumeChange />
                            </TabPanel> : null}
                        </Paper>
                    </Grid>
                </Grid>
            </main>
        </div >
    )
}

export default withStyles(styles, { withTheme: true })(Home);