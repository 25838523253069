import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Auth0Provider } from "./react-auth0-spa";
import history from "./Helpers/history";
import { IntlProvider } from "./Components/IntlContext";
import TelemetryProvider from './Components/_helper/TelemetryProvider';


// if (!process.env.REACT_APP_MODE.includes("DEV") && window.location.host.endsWith(".com.cn")) {
//     console.log(window.location.host, "host");
//     console.log(window.location.search, "search");

//     var query = window.location.search;
//     var urlToRedirect = window.location.host.replace(".com.cn", ".com") + query;
//     console.log(urlToRedirect, 'redirect');

//     window.location.replace(urlToRedirect);
// }

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
    history.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
};


ReactDOM.render(
    <TelemetryProvider>
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0DOMAIN}
            client_id={process.env.REACT_APP_AUTH0CLIENTID}
            redirect_uri={window.location.origin}
            onRedirectCallback={onRedirectCallback}>
            <IntlProvider>
                <App />
            </IntlProvider>
        </Auth0Provider>
    </TelemetryProvider>,

    document.getElementById("root")
);