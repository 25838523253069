import React from "react";
import { IntlProvider, addLocaleData } from "react-intl";

import localeData_en from 'react-intl/locale-data/en';
import localeData_zh from 'react-intl/locale-data/zh';
import messages_zhcn from '../Translations/zh-CN.json';
import messages_zhtw from '../Translations/zh-TW.json';

addLocaleData([...localeData_en, ...localeData_zh]);

const { Provider, Consumer } = React.createContext();
const localeEn = 'en';
const localeZhCn = 'zh-CN'; //simplified
const localeZhSg = 'zh-SG'; //simplified
const localeZhTw = 'zh-TW'; //traditional
const localeZhKh = 'zh-HK'; //traditional
const localeZhMo = 'zh-MO'; //traditional

const supportedNonDefaultLocales = {
    [localeZhCn]: messages_zhcn,
    [localeZhKh]: messages_zhtw,
    [localeZhMo]: messages_zhtw,
    [localeZhSg]: messages_zhcn,
    [localeZhTw]: messages_zhtw
};

class IntlProviderWrapper extends React.Component {
    constructor(...args) {
        super(...args);

        this.switchToEnglish = () => {
            this.setState({ locale: localeEn, messages: null });
        }

        this.switchToChineseSimplified = () => {
            this.setState({ locale: localeZhCn, messages: messages_zhcn });
        }

        this.switchToChineseTraditional = () => {
            this.setState({ locale: localeZhTw, messages: messages_zhtw });
        }

        const browserLanguages = navigator.languages;


        //fallback if nothing matches to browser defined languages
        var locale = localeZhCn;
        var messages = messages_zhcn;


        //iterate all browser defined languages, set if one matches
        for (var i = 0; i < browserLanguages.length; i++) {
            var browserLanguage = browserLanguages[i];
            //the english translations are set as default values in the intl system, therefore, no messages file needs to be assigned
            if (browserLanguage.startsWith(localeEn)) {
                messages = null;
                locale = localeEn;
                break;
            } else if (Object.keys(supportedNonDefaultLocales).includes(browserLanguage)) {
                locale = browserLanguage;
                messages = supportedNonDefaultLocales[browserLanguage];
                break; // break from this loop if first match was found, otherwise a language defined with lower priority will be used
            }
        }



        // pass everything in state to avoid creating object inside render method (like explained in the documentation)
        this.state = {
            locale: locale,
            messages: messages,
            switchToEnglish: this.switchToEnglish,
            switchToChineseSimplified: this.switchToChineseSimplified,
            switchToChineseTraditional: this.switchToChineseTraditional,
        };
    }

    render() {
        const { children } = this.props;
        const { locale, messages } = this.state;
        return (
            <Provider value={this.state}>
                <IntlProvider
                    key={locale}
                    locale={locale}
                    messages={messages}
                    defaultLocale="en"
                >
                    {children}
                </IntlProvider>
            </Provider>
        );
    }
}

export { IntlProviderWrapper as IntlProvider, Consumer as IntlConsumer };