import React, { useEffect} from "react";
import Chart from "chart.js";
import * as constants from './constants';
import FittingRanges from './FittingRanges.json';



function FittingRangeChart(props) {

    let chartRef = React.createRef();

  useEffect(() => {
    const myChartRef = chartRef.current.getContext("2d");

    new Chart(myChartRef, {
        type: "line",
        data: {
            //Bring in data
            labels: ["500", "1000", "2000", "4000",],
            datasets: [
                {
                    borderColor: props.color,
                    data: [props.audiogram.Hz500, props.audiogram.Hz1K, props.audiogram.Hz2K, props.audiogram.Hz4K],
                    pointStyle: 'crossRot',
                    pointRadius: 10,
                    tension: 0,
                    fill: false,
                },
                {
                    backgroundColor: 'rgba(230, 230, 230,  0.8)',
                    fill: false,  //no fill here
                    pointRadius: 0,
                    tension: 0,
                    borderWidth: 1,
                    data: (FittingRanges.find(a => a.uri == props.uri)).fittingRangemin 
                },
                {
                    backgroundColor: 'rgba(230, 230, 230,  0.8)',
                    fill: '-1', //fill until previous dataset
                    pointRadius: 0,
                    tension: 0,
                    borderWidth: 1,
                    data: (FittingRanges.find(a => a.uri == props.uri)).fittingRangeMax
                }
            ],

        },
        options: {
            legend: {
                display: false
             },
             tooltips: {
                enabled: false
             },
            scales: {
                xAxes: [{
                    // id: 'x-axis-0',
                    display: true,
                }],
                yAxes: [{
                    // id: 'y-axis-0',
                    display: true,
                    ticks: {
                        reverse: true,
                        beginAtZero: false,
                        steps: 20,
                        min: 0,
                        max: 120
                    }
                }]
            }
        }
    });
  }, []);

    return (
        <canvas
            id="myChart"
            ref={chartRef}
        />
    )
}
export default FittingRangeChart



