import React, { useState, useEffect, useRef } from "react";
import FirstFitSide from './FirstFitSide'
import ApplyArc from './ApplyArc'

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import * as constants from './constants';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MenuBar from './MenuBar';
import Paper from '@material-ui/core/Paper';

import { FormattedMessage } from "react-intl";

const styles = theme => ({
    root: {
        background: "red",
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(3),
    },
    paper: {
        margin: theme.spacing(2),
        maxWidth: "1000px",
        align: "center",
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    tabbar: {
        backgroundColor: '#dddddd',
    },
    toolbar: theme.mixins.toolbar,
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        align: "center",
    },
    greeting: {
        flexGrow: 1,
        align: "left",
    },
    listItem: {
        maxWidth: "200px",
    },
    list: {
        maxWidth: "230px",
    }
});

function FirstFit(props) {


    let search = window.location.search;
    let params = new URLSearchParams(search);
    let devLeft = params.get("devLeft");
    let devRight = params.get("devRight");
    let clusterLeft = params.get("clusterLeft");
    let clusterRight = params.get("clusterRight");





    const [dialogOpen, setDialogOpen] = useState(false);
    const [leftSelection, setLeftSelection] = useState("");
    const [rightSelection, setRightSelection] = useState("");
    const [inputValid, setInputValid] = useState(false);
    const [pairingIdLeft, setPairingIdLeft] = useState(0);
    const [pairingIdRight, setPairingIdRight] = useState(1);

    const { classes } = props;
    const leftSelector = useRef(null);
    const rightSelector = useRef(null);


    useEffect(() => {
        let pairIdLeft = Math.floor(Math.random() * (15 - 0 + 1)) + 0;

        let pairIdRight = 0
        do {
            pairIdRight = Math.floor(Math.random() * (15 - 0 + 1)) + 0;
        } while (pairIdRight === pairIdLeft)

        setPairingIdLeft(pairingIdLeft);
        setPairingIdRight(pairingIdRight);
    }, []);

    useEffect(() => {
        validateData();
    }, [leftSelection]);

    useEffect(() => {
        validateData();
    }, [rightSelection]);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    }

    const updateLeftDevice = (selection) => {
        setLeftSelection(selection);
    }

    const updateRightDevice = (selection) => {
        setRightSelection(selection);
    }

    const resetSettings = () => {
        leftSelector.current.reset();
        rightSelector.current.reset();
    }

    const validateData = () => {
        var leftUnselected = (leftSelection.device === "" && leftSelection.cluster === 0) || leftSelection === "";
        var leftSelected = (leftSelection.device !== "" && leftSelection.cluster > 0);

        var rightUnselected = (rightSelection.device === "" && rightSelection.cluster === 0) || rightSelection === "";
        var rightSelected = (rightSelection.device !== "" && rightSelection.cluster > 0);

        let isValid = (leftUnselected && rightSelected) || (leftSelected && rightUnselected) || (leftSelected && rightSelected);

        setInputValid(isValid);
    }


    return (
        <div style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
            <MenuBar />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center">
                    <Grid item >
                        <Paper className={classes.paper} width="1000px">
                            <Grid
                                container
                                direction="column"
                                alignItems="center"
                                justify="center">
                                <Grid
                                    container
                                    justify="center"
                                    spacing={3}
                                    alignItems="center"  >
                                    <Grid item xs>
                                        <FirstFitSide side={<FormattedMessage id="general.left" defaultMessage="Left" />} ref={leftSelector} color={constants.COLOR_LEFT} preSelectedDevice={devLeft} preSelectedCluster={clusterLeft}  updateSelection={updateLeftDevice} />
                                    </Grid>
                                    <Divider orientation="vertical" />
                                    <Grid item xs>
                                        <FirstFitSide side={<FormattedMessage id="general.right" defaultMessage="Right" />} ref={rightSelector} color={constants.COLOR_RIGHT} preSelectedDevice={devRight} preSelectedCluster={clusterRight}  updateSelection={updateRightDevice} />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    justify="center"
                                    spacing={3}
                                    alignItems="center"  >
                                    <Grid item xs={12}>
                                        <Button variant="contained" color="primary" className={classes.button} disabled={!inputValid} onClick={handleDialogOpen}>
                                            <FormattedMessage
                                                id="general.applyfirstfit"
                                                defaultMessage="APPLY FIRST FIT" />
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Dialog
                                    disableBackdropClick
                                    open={dialogOpen}
                                    onClose={handleDialogClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description">
                                    <DialogContent>
                                        <ApplyArc
                                            leftDevice={leftSelection}
                                            rightDevice={rightSelection}
                                            pairingIdLeft={pairingIdLeft}
                                            pairingIdRight={pairingIdRight}
                                            resetSettings={resetSettings}
                                            handleClose={handleDialogClose} />
                                    </DialogContent>
                                </Dialog>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </main>
        </div >
    )
}

export default withStyles(styles, { withTheme: true })(FirstFit);