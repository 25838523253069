import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from "react";
import DeviceSelect from "./DeviceSelect";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { FormattedMessage } from "react-intl";
import Slider from '@material-ui/core/Slider';
import { IconButton } from "@material-ui/core";
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';


const styles = theme => ({
    root: {
        background: "red",
        flexGrow: 1,
    },
    grid: {
        padding: theme.spacing(1),
    },
    iconButton: {
        padding: "0px 12px 0px 12px"
    }
});

const FirstFitSide = forwardRef((props, ref) => {

    const [selectedDevice, setSelectedDevice] = useState("");
    const [selectedCluster, setSelectedCluster] = useState(5);
    const [volume, setVolume] = useState(3);
    const [masterGain, setMasterGain] = useState(0);
    const [bands, setBands] = useState({ bd1: 0, bd2: 0, bd3: 0, bd4: 0 });
    const [sqIncreased, setSqIncreased] = useState(false);
    const [sqDecreased, setSqDecreased] = useState(false);

    const { classes } = props;
    const deviceSelector = useRef();

    useImperativeHandle(ref, () => ({
        reset: () => {
            reset();
        }
    }))

    useEffect(() => {


        const selection = {
            device: selectedDevice,
            cluster: selectedCluster,
            volume: volume,
            band1: bands.bd1,
            band2: bands.bd2,
            band3: bands.bd3,
            band4: bands.bd4,
            masterGain: masterGain,
        };

        props.updateSelection(selection);
    }, [selectedCluster, selectedDevice, bands, volume, masterGain]);


    //   useEffect(() => {
    //       console.log(props.preSelectedCluster, "init");
    //     if(props.preSelectedCluster){
    //         setSelectedCluster(parseInt(props.preSelectedCluster));
    //     }
    //   }, []);


    const handleClusterChange = (event, newCluster) => {
        console.log("handleclusterchange");
        setSelectedCluster(newCluster);
    }

    const handleSetSelectedDevice = (device) => {
        console.log("handledevicechange");
        setBands({ ...bands, bd1: 0, bd2: 0, bd3: 0, bd4: 0 })
        setVolume(3);
        setSelectedDevice(device);
        setSelectedCluster(0);
        setMasterGain(0);
    }

    const decreaseFeedback = () => {
        if (bands.bd3 > -12) {
            let b = bands;
            b.bd3 = bands.bd3 - 3;
            setBands({ ...bands, bd3: b.bd3 })
        }
    }

    const increaseMasterGain = () => {
        let b = bands;
        if (b.bd1 < 9) b.bd1 += 3;
        if (b.bd2 < 9) b.bd2 += 3;
        if (b.bd3 < 9) b.bd3 += 3;
        if (b.bd4 < 9) b.bd4 += 3;
        setBands({ ...bands, bd1: b.bd1, bd2: b.bd2, bd3: b.bd3, bd4: b.bd4 })
    }

    const decreaseMasterGain = () => {

        let b = bands;

        if (b.bd1 > -12) b.bd1 -= 3;
        if (b.bd2 > -12) b.bd2 -= 3;
        if (b.bd3 > -12) b.bd3 -= 3;
        if (b.bd4 > -12) b.bd4 -= 3;

        setBands({ ...bands, bd1: b.bd1, bd2: b.bd2, bd3: b.bd3, bd4: b.bd4 })
    }

    const increaseSpeech = () => {
        let b = bands;

        if (b.bd2 === b.bd3 && b.bd3 < 9) {
            b.bd3 += 3;
        } else {
            if (b.bd3 < 9) b.bd3 += 3;
            if (b.bd2 < 9) b.bd2 += 3;
        }
        setBands({ ...bands, bd1: b.bd1, bd2: b.bd2, bd3: b.bd3, bd4: b.bd4 })
    }

    const decreaseSpeech = () => {
        let b = bands;
        if (b.bd3 > -12) b.bd3 -= 3;
        if (b.bd2 > -12) b.bd2 -= 3;
        setBands({ ...bands, bd1: b.bd1, bd2: b.bd2, bd3: b.bd3, bd4: b.bd4 })
    }

    const increaseSoundQuality = () => {
        let b = bands;

        //if the sq has not been increased before, only band1 and 4 will be changed

        if (b.bd1 > -12) b.bd1 -= 3;
        if (b.bd2 > -12 && sqIncreased) b.bd2 -= 3;
        if (b.bd3 < 9 && sqIncreased) b.bd3 += 3;
        if (b.bd4 < 9) b.bd4 += 3;

        setBands({ ...bands, bd1: b.bd1, bd2: b.bd2, bd3: b.bd3, bd4: b.bd4 })
        setSqIncreased(true);
    }

    const decreaseSoundQuality = () => {
        //if the sq has not been increased before, only band1 and 4 will be changed
        let b = bands;

        if (b.bd1 < 9) b.bd1 += 3;
        if (b.bd2 < 9 && sqDecreased) b.bd2 += 3;
        if (b.bd3 > -12 && sqDecreased) b.bd3 -= 3;
        if (b.bd4 > -12) b.bd4 -= 3;

        setBands({ ...bands, bd1: b.bd1, bd2: b.bd2, bd3: b.bd3, bd4: b.bd4 })
        setSqDecreased(true);
    }

    const increaseOwnVoice = () => {
        let b = bands;

        if (b.bd1 < 9) b.bd1 += 3;
        if (b.bd2 < 9) b.bd2 += 3;

        setBands({ ...bands, bd1: b.bd1, bd2: b.bd2, bd3: b.bd3, bd4: b.bd4 })
    }

    const decreaseOwnVoice = () => {
        let b = bands;

        if (b.bd1 > -12) b.bd1 -= 3;
        if (b.bd2 > -12) b.bd2 -= 3;

        setBands({ ...bands, bd1: b.bd1, bd2: b.bd2, bd3: b.bd3, bd4: b.bd4 })
    }

    const reset = () => {
        setSelectedDevice("");
        deviceSelector.current.reset();
    }

    var clusterButtons = [];

    for (let i = 1; i < 7; i++) {

        let disabled = true;
        if (selectedDevice !== "" && i <= selectedDevice.cluster) {
            disabled = false;
        }
        clusterButtons.push(<ToggleButton disabled={disabled} key={"btn-cluster-" + i} value={i} id={"btn-cluster-" + i} >{i}</ToggleButton>)
    }

    return (
        <Grid container justify="center" alignItems="center">
            <Grid item xs={12} className={props.classes.grid}>
                <h2 style={{ color: props.color }}>{props.side}</h2>
            </Grid>
            <Grid item xs={12} className={props.classes.grid}>
                <DeviceSelect preSelected={props.preSelectedDevice} ref={deviceSelector} selectedDevice={handleSetSelectedDevice}></DeviceSelect>
            </Grid>
            <Grid item xs={12} className={props.classes.grid}>
                <ToggleButtonGroup
                    label={<FormattedMessage
                        id="general.selectacluster"
                        defaultMessage="Select a cluster" />}
                    size="large"
                    value={selectedCluster}
                    exclusive
                    onChange={handleClusterChange}
                    aria-label="text alignment">
                    {clusterButtons}
                </ToggleButtonGroup>
            </Grid>

            <Grid item xs={12} className={props.classes.grid} justify="center" alignItems="center">
               <div> <FormattedMessage
                    id="general.mastergain"
                    defaultMessage="Master Gain" /></div>
                <Slider
                    disabled={!selectedDevice || !selectedDevice.masterGain}
                    value={masterGain}
                    onChange={(event, value) => setMasterGain(value)}
                    aria-labelledby="discrete-slider"
                    style={{ width: 250 }}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    track={false}
                    min={-8}
                    max={+7} />
                <Grid item className={classes.grid}>
                    {masterGain}
                </Grid>
            </Grid>

            <Grid item xs={12} className={props.classes.grid} justify="center" alignItems="center">
                <FormattedMessage
                    id="general.4bdeq"
                    defaultMessage="4BD EQ"
                />
                <Grid container justify="center" alignItems="stretch">
                    <Grid item className={classes.grid}>
                        <Grid container justify="center" direction="column" alignItems="center">
                            <Grid item className={classes.grid}>
                                <Slider
                                    disabled={!selectedDevice || !selectedDevice.fourBand}
                                    orientation="vertical"
                                    value={bands.bd1}
                                    onChange={(event, value) => setBands({ ...bands, bd1: value })}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    style={{ height: 150 }}
                                    step={3}
                                    marks
                                    track={false}
                                    min={-12}
                                    max={+9} />
                            </Grid>
                            <Grid item className={classes.grid}>
                                {bands.bd1}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.grid}>
                        <Grid container justify="center" direction="column" alignItems="center">
                            <Grid item className={classes.grid}>
                                <Slider
                                    disabled={!selectedDevice || !selectedDevice.fourBand}
                                    orientation="vertical"
                                    value={bands.bd2}
                                    onChange={(event, value) => setBands({ ...bands, bd2: value })}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    style={{ height: 150 }}
                                    step={3}
                                    marks
                                    track={false}
                                    min={-12}
                                    max={+9} />
                            </Grid>
                            <Grid item className={classes.grid}>
                                {bands.bd2}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.grid}>
                        <Grid container justify="center" direction="column" alignItems="center">
                            <Grid item className={classes.grid}>
                                <Slider
                                    disabled={!selectedDevice || !selectedDevice.fourBand}
                                    orientation="vertical"
                                    value={bands.bd3}
                                    onChange={(event, value) => setBands({ ...bands, bd3: value })}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    style={{ height: 150 }}
                                    step={3}
                                    marks
                                    track={false}
                                    min={-12}
                                    max={+9} />
                            </Grid>
                            <Grid item className={classes.grid}>
                                {bands.bd3}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.grid}>
                        <Grid container justify="center" direction="column" alignItems="center">
                            <Grid item className={classes.grid}>
                                <Slider
                                    disabled={!selectedDevice || !selectedDevice.fourBand}
                                    orientation="vertical"
                                    value={bands.bd4}
                                    onChange={(event, value) => setBands({ ...bands, bd4: value })}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    style={{ height: 150 }}
                                    step={3}
                                    marks
                                    track={false}
                                    min={-12}
                                    max={+9} />
                            </Grid>
                            <Grid item className={classes.grid}>
                                {bands.bd4}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.grid}>
                    <Grid container justify="center" direction="row" alignItems="flex-start">
                        <Grid item xs={8} className={classes.grid}>
                            <FormattedMessage
                                id="general.mastergain"
                                defaultMessage="Gain" />
                        </Grid>
                        <Grid item xs={2} className={classes.grid}>
                            <IconButton className={classes.iconButton} color="primary" onClick={decreaseMasterGain} disabled={!selectedDevice || !selectedDevice.fourBand}>
                                <RemoveIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={2} className={classes.grid}>
                            <IconButton className={classes.iconButton} color="primary" onClick={increaseMasterGain} disabled={!selectedDevice || !selectedDevice.fourBand}>
                                <AddIcon />
                            </IconButton>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item className={classes.grid}>
                    <Grid container m={-5} justify="center" direction="row" alignItems="flex-start">
                        <Grid item xs={8} className={classes.grid}>
                            <FormattedMessage
                                id="general.ownvoice"
                                defaultMessage="Own Voice" />
                        </Grid>
                        <Grid item xs={2} className={classes.grid}>
                            <IconButton className={classes.iconButton} color="primary" onClick={decreaseOwnVoice} disabled={!selectedDevice || !selectedDevice.fourBand}>
                                <RemoveIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={2} className={classes.grid}>
                            <IconButton className={classes.iconButton} color="primary" onClick={increaseOwnVoice} disabled={!selectedDevice || !selectedDevice.fourBand}>
                                <AddIcon />
                            </IconButton>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item className={classes.grid}>
                    <Grid container justify="center" direction="row" alignItems="flex-start">
                        <Grid item xs={8} className={classes.grid}>
                            <FormattedMessage
                                id="general.speech"
                                defaultMessage="Speech" />
                        </Grid>
                        <Grid item xs={2} className={classes.grid}>
                            <IconButton className={classes.iconButton} color="primary" onClick={decreaseSpeech} disabled={!selectedDevice || !selectedDevice.fourBand}>
                                <RemoveIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={2} className={classes.grid}>
                            <IconButton className={classes.iconButton} color="primary" onClick={increaseSpeech} disabled={!selectedDevice || !selectedDevice.fourBand}>
                                <AddIcon />
                            </IconButton>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item className={classes.grid}>
                    <Grid container justify="center" direction="row" alignItems="flex-start">
                        <Grid item xs={8} className={classes.grid}>
                            <FormattedMessage
                                id="general.soundquality"
                                defaultMessage="Sound Quality" />
                        </Grid>
                        <Grid item xs={2} className={classes.grid}>
                            <IconButton className={classes.iconButton} color="primary" onClick={decreaseSoundQuality} disabled={!selectedDevice || !selectedDevice.fourBand}>
                                <RemoveIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={2} className={classes.grid}>
                            <IconButton className={classes.iconButton} color="primary" onClick={increaseSoundQuality} disabled={!selectedDevice || !selectedDevice.fourBand}>
                                <AddIcon />
                            </IconButton>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item className={classes.grid}>
                    <Grid container justify="center" direction="row" alignItems="flex-start">
                        <Grid item xs={8} className={classes.grid}>
                            <FormattedMessage
                                id="general.feedback"
                                defaultMessage="Feedback" />
                        </Grid>
                        <Grid item xs={2} className={classes.grid}>

                        </Grid>
                        <Grid item xs={2} className={classes.grid}>
                            <IconButton className={classes.iconButton} color="primary" onClick={decreaseFeedback} disabled={!selectedDevice || !selectedDevice.fourBand}>
                                <RemoveIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item className={props.classes.grid}>
                    <FormattedMessage
                        id="general.volume"
                        defaultMessage="Volume"
                    />
                </Grid>
                <Grid container justify="center" direction="column" alignItems="center">
                    <Grid container className={props.classes.grid} alignItems="center">
                        <Slider
                            disabled={!selectedDevice}
                            value={volume}
                            onChange={(event, value) => setVolume(value)}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="auto"
                            style={{ width: 250 }}
                            step={1}
                            marks
                            track={false}
                            min={0}
                            max={+15} />
                        {volume}
                    </Grid>
                </Grid> */}
            </Grid>
        </Grid>
    )

});
export default withStyles(styles, { withTheme: true })(FirstFitSide);