import React, { useState, useEffect } from "react";
import * as constants from './constants';
import ApiService from "../Helpers/HttpConnect";

import { useAuth0 } from "../react-auth0-spa";

import { withStyles } from '@material-ui/core/styles';

import TenantSettingsService from './../Helpers/TenantSettingsService'

import Grid from '@material-ui/core/Grid';

import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import CircularProgress from '@material-ui/core/CircularProgress';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import DoneIcon from '@material-ui/icons/Done';
import { IconButton } from "@material-ui/core";

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { FormattedMessage } from "react-intl";

import { ai } from './_helper/TelemetryService';

const styles = theme => ({
    root: {
        background: "red",
        flexGrow: 1,
    },
    paper: {
        margin: theme.spacing(2),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
});

function FourBandChangeStepper(props) {
    const [activeStep, setActiveStep] = useState(0);
    const [name, setName] = useState("");
    const [settingsLink, setSettingsLink] = useState("");
    const [canClose, setCanClose] = useState(true);
    const [linkCopied, setlinkCopied] = useState(false);
    const [summaryCopied, setSummaryCopied] = useState(false);

    const { classes } = props;
    const { user } = useAuth0();

    useEffect(() => {
        if (activeStep === 2) {
            
            var settings = []
            var leftEar, rightEar = {}
    
            if (props.leftDevice && props.leftDevice.device) {
                let band1Setting = props.leftDevice.band1;
                let band2Setting = props.leftDevice.band2;
                let band3Setting = props.leftDevice.band3;
                let band4Setting = props.leftDevice.band4;
                let volume = props.leftDevice.vcEnabled ? props.leftDevice.volume : null;

                let earSide = 1
                let hiuri = props.leftDevice.device.uri;
                leftEar = {
                    band1Setting,
                    band2Setting,
                    band3Setting,
                    band4Setting,
                    earSide,
                    volume,
                    hiuri
                }
                settings.push(leftEar)
            }
            if (props.rightDevice && props.rightDevice.device) {
                let band1Setting = props.rightDevice.band1;
                let band2Setting = props.rightDevice.band2;
                let band3Setting = props.rightDevice.band3;
                let band4Setting = props.rightDevice.band4;
                let volume = props.rightDevice.vcEnabled ? props.rightDevice.volume : null;
                let earSide = 2
                let hiuri = props.rightDevice.device.uri;
                rightEar = {
                    band1Setting,
                    band2Setting,
                    band3Setting,
                    band4Setting,
                    earSide,
                    volume,
                    hiuri
                }
                settings.push(rightEar)
            }
    
            let data = {
                name: name,
                brand: TenantSettingsService.get(user).brand,
                tenantId: user["https://my-domain.my-company.com/user_metadata"].tenant,
                settings
           }

            sendData(data);
        }
        if (activeStep === 3) {
            setCanClose(false);
        }
    }, [activeStep]);

    useEffect(() => {
        if (linkCopied && summaryCopied) {
            setCanClose(true);
        }
    }, [linkCopied, summaryCopied]);

    useEffect(() => {
        if (settingsLink) {
            track();
        }
    }, [settingsLink]);

    const handleStepContinue = () => {
        setActiveStep(previousActiveStep => previousActiveStep + 1);
    }

    const handleStepBack = () => setActiveStep(previousActiveStep => previousActiveStep - 1);

    const handleClose = () => {
        if (activeStep === 3)
            props.resetSettings();
        props.handleClose();
    }

    const handleNameChange = (event) => {
        setName(event.target.value);
    }

    const handleCopyLinkClick = () => {
        setlinkCopied(true);
    }

    const handleCopySummaryClick = () => {
        setSummaryCopied(true);
    }

  

    const sendData = (data) => {

        let uri = process.env.REACT_APP_PORTALURI;

        if (process.env.REACT_APP_MODE.includes("PROD")) {
            uri = TenantSettingsService.get(user).url;
            var tld = ".com.cn/?id="
            if (window.location.host.endsWith(".com")) {
                tld = ".com/?id="
            }
            uri = uri + tld;
        }

        ApiService.postFourBandChange(data, uri)
            .then(res => {
                setActiveStep(3);
                setSettingsLink(res);
            })
            .catch(error => {
                console.log("Error", error);
            });
    };

    const track = () => {
        let type =  props.rightDevice.vcEnabled || props.leftDevice.vcEnabled ? '4BDVC' : '4BD';

        ai.trackEvent("Settings created", {
            'url': settingsLink,
            'name': name,
            'leftDevice': props.leftDevice.device !== "" ? props.leftDevice.device.name : null,
            'leftBand1': props.leftDevice.device !== "" ? props.leftDevice.band1 : null,
            'leftBand2': props.leftDevice.device !== "" ? props.leftDevice.band2 : null,
            'leftBand3': props.leftDevice.device !== "" ? props.leftDevice.band3 : null,
            'leftBand4': props.leftDevice.device !== "" ? props.leftDevice.band4 : null,
            'leftVolume': props.leftDevice.device !== "" ? props.leftDevice.volume : null,
            'rightDevice': props.rightDevice.device !== "" ? props.rightDevice.device.name : null,
            'rightBand1': props.rightDevice.device !== "" ? props.rightDevice.band1 : null,
            'rightBand2': props.rightDevice.device !== "" ? props.rightDevice.band2 : null,
            'rightBand3': props.rightDevice.device !== "" ? props.rightDevice.band3 : null,
            'rightBand4': props.rightDevice.device !== "" ? props.rightDevice.band4 : null,
            'rightVolume': props.rightDevice.device !== "" ? props.rightDevice.volume : null,
            'setup': props.leftDevice.device !== "" && props.rightDevice.device !== "" ? "BINAURAL" : "MONAURAL",
            'type': type,
            'tenant': user["https://my-domain.my-company.com/user_metadata"].tenant,
            'user': user.nickname
        });

    }

    const summary = (textOnly)=>{
        let summary = "" ;
        let endline =  "<br>";

        if(textOnly)
            endline = '\n';

        summary += "4 BD EQ Change" + endline;
        summary += name + endline + endline;
        

        summary += "LEFT: ";
        if(props.leftDevice.device !== ""){
            summary +=  props.leftDevice.device.name + endline;
            summary += "Band1: " + props.leftDevice.band1 + endline; 
            summary += "Band2: " + props.leftDevice.band2 + endline;
            summary += "Band3: " + props.leftDevice.band3 + endline;
            summary += "Band4: " + props.leftDevice.band4 + endline;
            let volume = "NO CHANGE";
            if(props.leftDevice.vcEnabled)
                volume = props.leftDevice.volume

            summary += "Volume: " + volume + endline;
        }else{
            summary += "NO CHANGE" + endline;
        }



        summary += "RIGHT: ";
        if(props.rightDevice.device !== ""){
           summary += props.rightDevice.device.name  + endline;
            summary += "Band1: " + props.rightDevice.band1 + endline; 
            summary += "Band2: " + props.rightDevice.band2 + endline;
            summary += "Band3: " + props.rightDevice.band3 + endline;
            summary += "Band4: " + props.rightDevice.band4 + endline;
            let volume = "NO CHANGE";
            if(props.rightDevice.vcEnabled)
                volume = props.rightDevice.volume

            summary += "Volume: " + volume + endline;
        }else{
            summary += "NO CHANGE" + endline;
        }

        

        summary += endline + settingsLink + endline;
        

        if (textOnly)
            return summary;
        else
            return <div dangerouslySetInnerHTML={{ __html: summary }} />;
    }

   
    const steps = [
        <FormattedMessage
            id="general.setCustomerName"
            defaultMessage="Set Customer Name" />,
        <FormattedMessage
            id="general.review"
            defaultMessage="Review" />,
        <FormattedMessage
            id="general.generateLink"
            defaultMessage="Generate Link" />,
        <FormattedMessage
            id="general.summary"
            defaultMessage="Summary" />];

    steps.push();


    return (
        <div>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <div>
                {/* Content */}
                <div>
                    {activeStep === 0 ?
                        <div>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Name/ID of Customer"
                                type="text"
                                fullWidth
                                onChange={handleNameChange}
                            />
                        </div>
                        : null}

                    {activeStep === 1 ?
                        <div>
                            <h3>{name}</h3>
                            <Grid
                                container
                                justify="space-evenly"
                                spacing={3}>
                                <Grid item >
                                    <h4 style={{ color: constants.COLOR_LEFT }}>
                                        <FormattedMessage
                                            id="general.left"
                                            defaultMessage="Left" /></h4>
                                    <TableContainer >
                                        <Table className={classes.table} aria-label="simple table" size="small">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <FormattedMessage
                                                            id="general.device"
                                                            defaultMessage="Device" />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {props.leftDevice.device !== "" ? props.leftDevice.device.name : "-"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <FormattedMessage
                                                            id="general.band1"
                                                            defaultMessage="Band 1" />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {props.leftDevice.device !== "" ? props.leftDevice.band1 : "-"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <FormattedMessage
                                                            id="general.band2"
                                                            defaultMessage="Band 2" />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {props.leftDevice.device !== "" ? props.leftDevice.band2 : "-"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <FormattedMessage
                                                            id="general.band3"
                                                            defaultMessage="Band 3" />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {props.leftDevice.device !== "" ? props.leftDevice.band3 : "-"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <FormattedMessage
                                                            id="general.band4"
                                                            defaultMessage="Band 4" />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {props.leftDevice.device !== "" ? props.leftDevice.band4 : "-"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <FormattedMessage
                                                            id="general.volume"
                                                            defaultMessage="Volume" />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {props.leftDevice.vcEnabled ? props.leftDevice.volume : "-"}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item >
                                    <h4 style={{ color: constants.COLOR_RIGHT }}>
                                        <FormattedMessage
                                            id="general.right"
                                            defaultMessage="Right" />
                                    </h4>
                                    <TableContainer >
                                        <Table className={classes.table} aria-label="simple table" size="small">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <FormattedMessage
                                                            id="general.device"
                                                            defaultMessage="Device" />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {props.rightDevice.device !== "" ? props.rightDevice.device.name : "-"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <FormattedMessage
                                                            id="general.band1"
                                                            defaultMessage="Band 1" />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {props.rightDevice.device !== "" ? props.rightDevice.band1 : "-"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <FormattedMessage
                                                            id="general.band2"
                                                            defaultMessage="Band 2" />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {props.rightDevice.device !== "" ? props.rightDevice.band2 : "-"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <FormattedMessage
                                                            id="general.band3"
                                                            defaultMessage="Band 3" />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {props.rightDevice.device !== "" ? props.rightDevice.band3 : "-"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <FormattedMessage
                                                            id="general.band4"
                                                            defaultMessage="Band 4" />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {props.rightDevice.device !== "" ? props.rightDevice.band4 : "-"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <FormattedMessage
                                                            id="general.volume"
                                                            defaultMessage="Volume" />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {props.rightDevice.vcEnabled ? props.rightDevice.volume : "-"}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </div>
                        : null}

                    {activeStep === 2 ?
                        <div>
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justify="center">
                                <CircularProgress disableShrink />
                            </Grid>
                        </div>
                        : null}

                    {activeStep === 3 ?
                        <div>
                            <Grid
                                container
                                justify="space-evenly"
                                spacing={3}
                                alignItems="center">

                                <Grid item xs={10}>
                                    <h5>{settingsLink}</h5>
                                </Grid>
                                <Grid item xs={2}>
                                    <CopyToClipboard text={settingsLink} onCopy={handleCopyLinkClick}>
                                        <IconButton color="primary" onClick={handleCopyLinkClick}>
                                            {linkCopied === false ? <FileCopyIcon /> : <DoneIcon />}
                                        </IconButton>
                                    </CopyToClipboard>
                                </Grid>
                                <Grid item xs={10}>
                                    <div>{summary(false)}</div>
                                </Grid>
                                <Grid item xs={2}>
                                    <CopyToClipboard text={summary(true)} onCopy={handleCopySummaryClick}>
                                        <IconButton color="primary" onClick={handleCopySummaryClick}>
                                            {summaryCopied === false ? <FileCopyIcon /> : <DoneIcon />}
                                        </IconButton>
                                    </CopyToClipboard>
                                </Grid>
                            </Grid>

                        </div>
                        : null}
                </div>
                {/* Buttons */}
                <Grid
                    container
                    justify="space-evenly"
                    spacing={3}
                    alignItems="center">
                    <Grid item xs={6}>
                        <Button disabled={activeStep === 3 || activeStep === 2 || activeStep === 0} color="primary" onClick={handleStepBack}>
                            <FormattedMessage
                                id="general.back"
                                defaultMessage="Back" />
                        </Button>
                        <Button disabled={activeStep === 3 || activeStep === 2 || name === ""} color="primary" onClick={handleStepContinue}>
                            <FormattedMessage
                                id="general.Continue"
                                defaultMessage="Continue" />
                        </Button>
                    </Grid>
                    <Grid item xs={6} align="right">
                        <Button disabled={!canClose} color="primary" onClick={handleClose}>
                            <FormattedMessage
                                id="general.Close"
                                defaultMessage="Close" />
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default withStyles(styles, { withTheme: true })(FourBandChangeStepper);