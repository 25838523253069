import getAuthHeader from "./AuthHeader";

class ApiService {

  postClusterChange(data, portalUri) {
    let url = process.env.REACT_APP_USECASEAPI + "/Settings/ClusterChange";

    return new Promise((resolve, reject) => {
      return this.post(url, data)
        .then(success => {
          resolve(portalUri + success.id);
        })
        .catch(error => {
          return reject(false);
        });
    })

  }

  postFourBandChange(data, portalUri) {
    let url = process.env.REACT_APP_USECASEAPI + "/Settings/FourBandEqualizer";

    return new Promise((resolve, reject) => {
      return this.post(url, data)
        .then(success => {

          resolve(portalUri + success.id);
        })
        .catch(error => {
          return reject(false);
        });
    })

  }

  postVcScChange(data, portalUri) {
    let url = process.env.REACT_APP_USECASEAPI + "/Settings/VcScChange";

    return new Promise((resolve, reject) => {
      return this.post(url, data)
        .then(success => {

          resolve(portalUri + success.id);
        })
        .catch(error => {
          return reject(false);
        });
    })

  }

  getHistory(name, tenantId) {
    let url = process.env.REACT_APP_USECASEAPI + "/Settings/GetCustomerHistory?name=" + name + "&tenantid=" + tenantId;

    return new Promise((resolve, reject) => {
      return this.get(url)
        .then(success => {
          resolve(success);
        })
        .catch(error => {
          return reject(false);
        });
    })
  }

  postDeviceRecommendation(data, onlyOnePerGroup) {
    let url = process.env.REACT_APP_USECASEAPI + "/Recommendation/V3?includeNotRecommended=true&onlyOnePerGroup=" + onlyOnePerGroup;

    return new Promise((resolve, reject) => {
      return this.post(url, data)
        .then(success => {
          resolve(success);
        })
        .catch(error => {
          return reject(false);
        });
    })
  }

  postSendSurveyMail(data) {
    let url = "https://nextgensendgridapi.azurewebsites.net/";
    return new Promise((resolve, reject) => {
      return this.post(url, data)
        .then(success => {
          resolve(success);
        })
        .catch(error => {
          return reject(false);
        });
    })
  }

  postSendDataToNovavision(data) {
    let url = "https://nextgensendgridapi.azurewebsites.net/nvresults";
    return new Promise((resolve, reject) => {
      return this.post(url, data)
        .then(success => {
          resolve(success);
        })
        .catch(error => {
          return reject(false);
        });
    })
  }



  post(url, data) {
    return new Promise((resolve, reject) => {
      return fetch(url, {
        method: "POST",
        headers: getAuthHeader(),
        body: JSON.stringify(data)
      })
        .then(result => {
          if (result.status >= 200 && result.status < 300) {
            // Examine the text in the response
            const contentType = result.headers.get("content-type");
            let responsePromise;
            if (contentType && contentType.indexOf("application/json") !== -1) {
              responsePromise = result.json();
            } else {
              responsePromise = result.text();
            }

            responsePromise
              .then(data => {
                resolve(data);
              })
              .catch(error => {
                console.log(error);
                resolve(true);
              });
          } else {
            result
              .json()
              .then(data => {
                reject({
                  status: result.status,
                  code: data.error_code,
                  message: data.message
                });
              })
              .catch(() => {
                return reject(false);
              });
          }
        })
        .catch(error => console.warn(error));
    });
  }

  get(url) {
    return new Promise((resolve, reject) => {
      return fetch(url, {
        method: "GET",
        headers: getAuthHeader(),
      })
        .then(result => {
          if (result.status >= 200 && result.status < 300) {
            // Examine the text in the response
            const contentType = result.headers.get("content-type");
            let responsePromise;
            if (contentType && contentType.indexOf("application/json") !== -1) {
              responsePromise = result.json();
            } else {
              responsePromise = result.text();
            }

            responsePromise
              .then(data => {
                resolve(data);
              })
              .catch(error => {
                console.log(error);
                resolve(true);
              });
          } else {
            result
              .json()
              .then(data => {
                reject({
                  status: result.status,
                  code: data.error_code,
                  message: data.message
                });
              })
              .catch(() => {
                return reject(false);
              });
          }
        })
        .catch(error => console.warn(error));
    });
  }
}



export default new ApiService();
