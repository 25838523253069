import React, { useState, useEffect } from "react";

import { useAuth0 } from "../react-auth0-spa";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from "react-intl";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MuiAlert from '@material-ui/lab/Alert';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ai } from './_helper/TelemetryService';
import MenuBar from './MenuBar';
import Paper from '@material-ui/core/Paper';
import ApiService from "../Helpers/HttpConnect";
import CircularProgress from '@material-ui/core/CircularProgress';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const styles = theme => ({
    root: {
        background: "red",
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(3),
    },
    paper: {
        margin: theme.spacing(2),
        maxWidth: "1000px",
        align: "center",
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    tabbar: {
        backgroundColor: '#dddddd',
    },
    toolbar: theme.mixins.toolbar,
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        align: "center",
    },
    greeting: {
        flexGrow: 1,
        align: "left",
    },
    listItem: {
        maxWidth: "200px",
    },
    list: {
        maxWidth: "230px",
    }
});



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const History = (props) => {
    const { user } = useAuth0();
    const { classes } = props;
    const [name, setName] = React.useState("");
    const [rows, setRows] = React.useState();
    const [resultsLoading, setResultsLoading] = React.useState(false);

    const getSettingsTypeClearText = (type) => {
        switch (type) {
            case 2: return "Cluster";
            case 1: return "4 Band";
        }
    }

    const loadHistory = () => {

        setResultsLoading(true);

        ApiService.getHistory(name, user["https://my-domain.my-company.com/user_metadata"].tenant)
            .then(res => {
                let tableEntries = [];

                if (res.history.length > 0) {

                    res.history.sort(function (a, b) {
                        // Turn your strings into dates, and then subtract them
                        // to get a value that is either negative, positive, or zero.
                        return new Date(b.date) - new Date(a.date);
                    });


                    let uniqueIds = Array.from(new Set(res.history.map(a => a.id)));

                    uniqueIds.map(id => {
                        let settings = res.history.filter(a => a.id === id);
                        let hasLeft = settings.some(a => a.earSide === 1);
                        let hasRight = settings.some(a => a.earSide === 2)

                        tableEntries.push({
                            date: settings[0].date,
                            settingsType: getSettingsTypeClearText(settings[0].settingsType),
                            link: id,
                            left: hasLeft,
                            right: hasRight,
                        })




                    });

                    uniqueIds = Array.from(new Set(res.history.map(a => a.id)));
                    let settingsModelBuilt = false;
                    let i = 0;
                    let leftFourBandFound = false;
                    let rightFourBandFound = false;
                    let leftClusterFound = false;
                    let rightClusterFound = false;
                    let settingsModel = {};

                    do {
                        let settings = res.history.filter(a => a.id === uniqueIds[i]);
                        let Left = settings.find(a => a.earSide === 1);
                        let Right = settings.find(a => a.earSide === 2);

                        if (settings[0].settingsType === 2) {
                            if (!leftClusterFound && Left) {

                                if (!leftFourBandFound) {
                                    settingsModel.LeftBand1 = 0;
                                    settingsModel.LeftBand2 = 0;
                                    settingsModel.LeftBand3 = 0;
                                    settingsModel.LeftBand4 = 0;
                                    settingsModel.LeftCluster = Left.cluster;
                                    if (i === 0) leftFourBandFound = true; //if this is the most current entry, the four band has been reset 
                                }
                                else {
                                    settingsModel.LeftCluster = Left.cluster;
                                }
                                leftClusterFound = true;
                            }
                            if (!rightClusterFound && Right) {

                                if (!rightFourBandFound) {
                                    settingsModel.RightBand1 = 0;
                                    settingsModel.RightBand2 = 0;
                                    settingsModel.RightBand3 = 0;
                                    settingsModel.RightBand4 = 0;
                                    settingsModel.RightCluster = Right.cluster;
                                    if (i === 0) rightFourBandFound = true; //if this is the most current entry, the four band has been reset 
                                }
                                else {
                                    settingsModel.RightCluster = Right.cluster;
                                }
                                rightClusterFound = true;
                            }
                        }
                        else if (settings[0].settingsType === 1) {
                            if (!leftFourBandFound && Left) {
                                settingsModel.LeftBand1 = Left.band1Setting;
                                settingsModel.LeftBand2 = Left.band2Setting;
                                settingsModel.LeftBand3 = Left.band3Setting;
                                settingsModel.LeftBand4 = Left.band4Setting;
                                leftFourBandFound = true;
                            }

                            if (!rightFourBandFound && Right) {
                                settingsModel.RightBand1 = Right.band1Setting;
                                settingsModel.RightBand2 = Right.band2Setting;
                                settingsModel.RightBand3 = Right.band3Setting;
                                settingsModel.RightBand4 = Right.band4Setting;
                                rightFourBandFound = true;
                            }
                        }

                        i++;


                    } while (!(!rightClusterFound && !rightFourBandFound && !leftClusterFound && !leftFourBandFound) && i < uniqueIds.length)
                }

                setRows(tableEntries);


                setResultsLoading(false);

            })
            .catch(error => {
                setResultsLoading(false);
                console.log("Error", error);
            });
    }






    return (
        <div style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
            <MenuBar />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                >

                    <Grid item >
                        <Paper className={classes.paper} width="1000px">
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                justify="center">


                                <TextField
                                    onChange={(event) => setName(event.target.value)}
                                    value={name}
                                    style={{ margin: 30 }}
                                    id="outlined-basic"
                                    label={
                                        <FormattedMessage
                                            id="questionnaire.nameid"
                                            defaultMessage="Customer Name/ID" />
                                    }
                                    value={name}
                                    variant="outlined" />


                                {resultsLoading ?

                                    <CircularProgress className={props.classes.button} disableShrink />
                                    :
                                    <Button variant="contained" color="primary" className={classes.button} onClick={loadHistory}>
                                        <FormattedMessage
                                            id="history.loadhistory"
                                            defaultMessage="Load History" />
                                    </Button>}

                                <TableContainer component={Paper}>
                                    <Table className={classes.table} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Date</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell>Link</TableCell>
                                                <TableCell>Left</TableCell>
                                                <TableCell>Right</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows && rows.map((row) => (
                                                <TableRow key={row.name}>
                                                    <TableCell component="th" scope="row">
                                                        {(new Date(row.date)).toString()}
                                                    </TableCell>
                                                    <TableCell>{row.settingsType}</TableCell>
                                                    <TableCell>{row.link}</TableCell>
                                                    <TableCell>{row.left ? "x" : ""}</TableCell>
                                                    <TableCell>{row.right ? "x" : ""}</TableCell>
                                                </TableRow>
                                            ))}

                                        </TableBody>
                                    </Table>
                                </TableContainer>


                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </main>
        </div >
    )
}

export default withStyles(styles, { withTheme: true })(History);