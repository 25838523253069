import React, { useState, useEffect } from "react";
import base64 from "base-64";
import { useAuth0 } from "../react-auth0-spa";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from "react-intl";
import TenantSettingsService from './../Helpers/TenantSettingsService'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ai } from './_helper/TelemetryService';
import { v4 as uuidv4 } from 'uuid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuBar from './MenuBar';
import Paper from '@material-ui/core/Paper';


const styles = theme => ({
    root: {
        background: "red",
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(3),
    },
    paper: {
        margin: theme.spacing(2),
        maxWidth: "1000px",
        align: "center",
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    tabbar: {
        backgroundColor: '#dddddd',
    },
    toolbar: theme.mixins.toolbar,
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        align: "center",
    },
    greeting: {
        flexGrow: 1,
        align: "left",
    },
    listItem: {
        maxWidth: "200px",
    },
    list: {
        maxWidth: "230px",
    }
});


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const GenerateShoeboxLink = (props) => {
    const { user } = useAuth0();
    let showAssessmentResultDefault = TenantSettingsService.get(user).assessmentShowResultDefault;

    const { classes } = props;
    const [name, setName] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [link, setLink] = React.useState('');
    const [showAssessmentResult, setShowAssessmentResult] = React.useState(showAssessmentResultDefault);



    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    useEffect(() => {        
        var dataToBeSent = {
            name: name,
            tenant: user["https://my-domain.my-company.com/user_metadata"].tenant,
            email: user.name
        };
        var encodedName = base64.encode(JSON.stringify(dataToBeSent));
        let url = TenantSettingsService.get(user).shoeboxUrl;
        url = url + '/?refid=' + encodedName;
        
        setLink(url);
    }, [name, showAssessmentResult]);

    const track = (url) => {
        ai.trackEvent("SBX Link created", {
            'url': url,
            'name': name,
            'tenant': user["https://my-domain.my-company.com/user_metadata"].tenant,
            'user': user.nickname
        });
    }


    return (
        <div style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
            <MenuBar />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                >

                    <Grid item >
                        <Paper className={classes.paper} width="1000px">
                            <Grid
                                container
                                direction="column"
                                alignItems="center"
                                justify="center">


                                <TextField
                                    onChange={(event) => setName(event.target.value)}
                                    value={name}
                                    style={{ margin: 30 }}
                                    id="outlined-basic"
                                    label={
                                        <FormattedMessage
                                            id="questionnaire.nameid"
                                            defaultMessage="Customer Name/ID" />
                                    }
                                    value={name}
                                    variant="outlined" />


                                <CopyToClipboard text={link}  >
                                    <Button variant="contained" color="primary" className={classes.button} onClick={() => { setName(''); setOpen(true); track(link) }}>
                                        <FormattedMessage
                                            id="shoebox.generatelink"
                                            defaultMessage="Generate Shoebox Link" />
                                    </Button>
                                </CopyToClipboard>

                            </Grid>
                            <Snackbar open={open} autoHideDuration={3000} onClose={handleSnackbarClose}>
                                <Alert onClose={handleSnackbarClose} severity="success">
                                    <FormattedMessage
                                        id="general.copiedtoclipboard"
                                        defaultMessage="Copied to clipboard" />
                                </Alert>
                            </Snackbar>
                        </Paper>
                    </Grid>
                </Grid>
            </main>
        </div >

    )
}

export default withStyles(styles, { withTheme: true })(GenerateShoeboxLink);