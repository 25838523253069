import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from "react";
import DeviceSelect from "./DeviceSelect";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { FormattedMessage } from "react-intl";


const styles = theme => ({
    root: {
        background: "red",
        flexGrow: 1,
    },
    grid: {
        padding: theme.spacing(1),
    }
});

const ClusterChangeSide= forwardRef((props, ref) =>  {

    const [selectedDevice, setSelectedDevice] = useState("");
    const [selectedCluster, setSelectedCluster] = useState(0);
    
    const deviceSelector = useRef();

    useImperativeHandle(ref, () => ({
        reset: () => {
            reset();
        }
      }))

    useEffect(() => {
        const selection = {
            device: selectedDevice,
            cluster: selectedCluster
        };
        props.updateSelection(selection);
      }, [selectedCluster, selectedDevice]);

      
    const handleClusterChange = (event, newCluster) => {
        setSelectedCluster(newCluster);
    }

    const handleSetSelectedDevice = (device) => {
        setSelectedDevice(device);
        setSelectedCluster(0);
    }

    const reset = () => {
        setSelectedDevice("");
        deviceSelector.current.reset();
    }

    var clusterButtons = [];

    for (let i = 1; i < 7; i++) {

        let disabled = true;
        if (selectedDevice !== "" && i <= selectedDevice.cluster) {
            disabled = false;
        }
        clusterButtons.push(<ToggleButton disabled={disabled} key={"btn-cluster-" + i} value={i} id={"btn-cluster-" + i} >{i}</ToggleButton>)
    }

    return (
        <Grid container justify="center" alignItems="center">
            <Grid item xs={12} className={props.classes.grid}>
                <h2 style={{ color: props.color }}>{props.side}</h2>
            </Grid>
            <Grid item xs={12} className={props.classes.grid}>
                <DeviceSelect ref={deviceSelector} selectedDevice={handleSetSelectedDevice}></DeviceSelect>
            </Grid>
            <Grid item xs={12} className={props.classes.grid}>
                <ToggleButtonGroup
                        label={<FormattedMessage
                        id="general.selectacluster"
                        defaultMessage="Select a cluster" />}
                    size="large"
                    value={selectedCluster}
                    exclusive
                    onChange={handleClusterChange}
                    aria-label="text alignment">
                    {clusterButtons}
                </ToggleButtonGroup>
            </Grid>
        </Grid>
    )

});
export default withStyles(styles, { withTheme: true })(ClusterChangeSide);